<template>
  <div class="app-container">
    <!--div class="page-title">
      <h2>{{ $t('village.villages') }}</h2>
    </div-->
    <top-count title="Total Account" :end-val="total" />
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="actives" border @change="getSubVillagesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
        <el-radio v-model="activeList" label="desactives" border @change="getSubVillagesList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterVillages">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubVillagesList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-select v-model="query.commune_id" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('commune.commune')" style="width: 100%;" @change="initCommuneFilter">
          <el-option v-for="(commune, index) in communes" :key="index" :label="commune.nom | uppercaseFirst" :value="commune.id" />
        </el-select>
        <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @input="handleFilterVillages" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterVillages">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer village'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateVillage">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" :label="$t('commune.commune')" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.commune_nom }}</span>
          </template>
        </el-table-column>

        <!-- el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column -->

        <el-table-column align="center" :label="$t('general.nom')">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!='corbeille' && checkPermission(['activer village'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              :disabled="!scope.row.is_visible"
              @change="setVillageStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setVillageStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['modifier village','visible village','supprimer village'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier village'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditVillage(scope.row.id);" />
            <el-button v-if="checkPermission(['visible village','supprimer village'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteVillage(scope.row.id, scope.row.is_visible, scope.row.nom);" />
            <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsModal(scope.row)" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > query.limit" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getVillagesList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('village.edit') + ' ' + currentVillage.nom : $t('village.AddVillage')"
        :visible.sync="dialogCreateVillage"
        :before-close="handleCloseVillageCreateDraw"
        :loading="villageCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="villageForm" :rules="rules" :model="currentVillage" label-position="left">
            <el-form-item :label="$t('commune.commune')" prop="commune_id">
              <el-select v-model="currentVillage.commune_id" class="filter-item" :placeholder="$t('commune.commune')" style="width: 100%;" prop="commune_id">
                <el-option
                  v-for="(commune, idex) in communes"
                  :key="idex"
                  :label="commune.nom | uppercaseFirst"
                  :value="commune.id"
                  :disabled="!commune.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('village.nom')" prop="nom">
              <el-input v-model="currentVillage.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentVillage.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateVillage=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="villageCreating" @click="editing ? updateVillage() : createVillage()">
              {{ villageCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :title="detailDataM !== null? 'Détails de la région : '+ detailDataM.nom: 'Détails'"
        :visible.sync="detailM"
        :before-close="handleCloseVillageCreateDraw"
        :loading="villageCreating"
        width="30%"
      >
        <div v-if="detailDataM !== null" class="content-inputs">
          <el-form ref="villageForm" :rules="rules" :model="detailDataM" label-position="left">
            <el-form-item :label="$t('commune.commune')" prop="commune_id">
              <el-select v-model="detailDataM.commune_id" class="filter-item" :placeholder="$t('commune.commune')" style="width: 100%;" prop="commune_id">
                <el-option
                  v-for="(commune, idex) in communes"
                  :key="idex"
                  :label="commune.nom | uppercaseFirst"
                  :value="commune.id"
                  :disabled="!commune.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('village.nom')" prop="nom">
              <el-input v-model="detailDataM.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="detailDataM.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="info" @click="detailM=false">
              {{ $t('permission.cancel') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        title="Importation"
        :visible.sync="drawer"
        :before-close="handleCloseVillageCreateDraw"
        width="60%"
      >
        <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
        <el-table :data="tableData" border highlight-current-row style="width: 100%;margin-top:20px;">
          <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
        </el-table>
        <el-button type="success" :disabled="!tableData.length>0" @click="bulkInsert">Insérer</el-button>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import TopCount from '@/views/localites/TopCount';
const villageResource = new Resource('villages');
const communeResource = new Resource('communes');
const bulkVillageResource = new Resource('villages_import');
export default {
  name: 'VillagesList',
  components: { UploadExcelComponent, Pagination, TopCount },
  directives: { waves, permission, role },
  props: {
    communesProps: {
      type: Array,
      default: () => [],
    },
    villagesProps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      communes: {},
      nomCommune: {},
      list: null,
      total: 0,
      loading: false,
      tableData: [],
      tableHeader: [],
      drawer: false,
      downloading: false,
      villageCreating: false,
      detailM: false,
      detailDataM: null,
      editing: false,
      activeList: 'disponibles',
      query: {
        page: 1,
        limit: 100,
        keyword: '',
        commune_id: '',
      },
      Communequery: {
        page: 1,
        limit: 10000,
        keyword: '',
        commune_id: '',
      },
      currentVillage: {},
      dialogCreateVillage: false,
      currentVillageId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('village.NomRequired'), trigger: 'blur' }],
        // description: [{ required: true, message: this.$t('village.DescriptionRequired'), trigger: 'blur' }],
        commune_id: [{ required: true, message: this.$t('village.CommuneRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    // this.communes = this.communesProps;
    // this.formatToObject(this.communes);
    this.resetNewVillage();
    // this.list = this.villagesProps; // this.getVillagesList();
    this.getVillagesList();
    this.getCommunesList();
  },
  methods: {
    handleSuccess(){
    },
    beforeUpload(){
    },
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    getCommuneName(communeId){
      const found = this.communes.find(commune => commune.id === communeId);
      if (found) {
        return found.userscount;
      } else {
        return '';
      }
    },
    handleCloseVillageCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelVillageForm() {
      this.$refs.VillageCreationDrawer.closeDrawer();
    },
    showDetailsModal(row){
      this.detailDataM = row;
      this.detailM = true;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        commune_id: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterVillages();
    },
    initCommuneFilter(){
      this.activeList = 'disponibles';
      this.handleFilterVillages();
    },
    formatToObject(communes){
      const _reg = [];
      communes.forEach(commune => {
        _reg[commune.id] = commune.nom;
      });
      this.nomCommune = _reg;
    },
    async getCommunesList() {
      const { data } = await communeResource.sublist('?typeApi="all"', this.Communequery);
      this.communes = data;
      const _reg = [];
      this.communes.forEach(commune => {
        _reg[commune.id] = commune.nom;
      });
      this.nomCommune = _reg;
    },
    async getVillagesList() {
      this.loading = true;
      const { data, meta } = await villageResource.sublist('?typeApi="all"', this.query);
      this.list = data;
      this.total = meta !== undefined ? meta.total : 0;
      // const { limit, page } = this.query;
      // const { data, meta } = await villageResource.list(this.query);
      /* this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      }); */
      // this.total = meta.total;
      this.loading = false;
    },
    bulkInsert(){
      var validHeaders = this.tableHeader.length === 2 && this.tableHeader[0] === 'nom' && this.tableHeader[1] === 'description';
      if (validHeaders) {
        const data = { 'village': this.tableData };
        bulkVillageResource
          .store(data)
          .then(async(response) => {
            this.$message({
              message: this.$t('village.NewVillage') + this.$t('produit.iscreatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            // this.currentProduitId = response.data.id;
            this.resetNewVillage();
            this.dialogCreateVillage = false;
            this.handleFilterVillages();
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.villageCreating = false;
            this.drawer = false;
            this.tableHeader = [];
            this.tableData = [];
          });
        // for (let index = 0; index < this.tableData.length; index++) {
        //   const element = this.tableData[index];
        //   console.log('A INSERER : ', element);
        //   if (element.nom !== '') {
        //     produitResource
        //       .store(element)
        //       .then(async(response) => {
        //         console.log('index ', index, response.data);
        //       })
        //       .catch(error => {
        //         console.log(error);
        //       })
        //       .finally(() => {
        //         this.drawer = false;
        //         this.tableHeader = [];
        //         this.tableData = [];
        //       });
        //   }
        // }
      } else {
        this.$message({
          message: 'DONNEES NON CONFORMES',
          type: 'info',
          duration: 5 * 1000,
        });
      }
    },
    async getSubVillagesList(subUrl) {
      this.loading = true;
      const { data } = await villageResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterVillages() {
      this.query.page = 1;
      this.getVillagesList();
      this.getCommunesList();
    },
    handleCreateVillage() {
      this.editing = false;
      this.resetNewVillage();
      this.dialogCreateVillage = true;
      this.$nextTick(() => {
        this.$refs['villageForm'].clearValidate();
      });
    },
    handleEditVillage(id){
      this.editing = true;
      this.resetNewVillage();
      this.currentVillage = this.list.find(abonne => abonne.id === id);
      this.dialogCreateVillage = true;
      this.$nextTick(() => {
        this.$refs['villageForm'].clearValidate();
      });
    },
    async setVillageStatus(id, type){
      const data = await villageResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteVillage(id, visible, name) {
      this.$confirm(this.$t('village.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer village']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              villageResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('village.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setVillageStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createVillage() {
      this.$refs['villageForm'].validate((valid) => {
        if (valid) {
          this.currentVillage.roles = [this.currentVillage.role];
          this.villageCreating = true;
          villageResource
            .store(this.currentVillage)
            .then(async(response) => {
              this.$message({
                message: this.$t('village.NewVillage') + ' ' + this.currentVillage.nom + ' ' + this.$t('village.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentVillageId = response.data.id;
              this.resetNewVillage();
              this.dialogCreateVillage = false;
              this.handleFilterVillages();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.villageCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    updateVillage() {
      this.$refs['villageForm'].validate((valid) => {
        if (valid) {
          this.villageCreating = true;
          villageResource.update(this.currentVillage.id, this.currentVillage).then(response => {
            this.resetNewVillage();
            this.handleFilterVillages();
            this.$message({
              message: this.$t('village.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateVillage = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.villageCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    resetNewVillage() {
      this.currentVillage = {
        nom: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('village.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des villages SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
