<template>
  <div class="app-container">
    <top-count title="Total Account" :end-val="total" />
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="actives" border @change="getSubRegionsList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
        <el-radio v-model="activeList" label="desactives" border @change="getSubRegionsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterRegions">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubRegionsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @input="handleFilterRegions" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterRegions">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer region'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateRegion">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <!-- el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column -->

        <el-table-column align="center" :label="$t('general.nom')">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!='corbeille' && checkPermission(['activer region'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setRegionStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="activeList=='corbeille' && checkRole(['admin'])" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setRegionStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['modifier region','visible region','supprimer region'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier region'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditRegion(scope.row.id);" />
            <el-button v-if="checkPermission(['visible region','supprimer region'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteRegion(scope.row.id, scope.row.is_visible, scope.row.nom);" />
            <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsModal(scope.row)" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > query.limit" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getRegionsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('region.edit') + ' ' + currentRegion.nom : $t('region.AddRegion')"
        :visible.sync="dialogCreateRegion"
        :before-close="handleCloseRegionCreateDraw"
        :loading="regionCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="regionForm" :rules="rules" :model="currentRegion" label-position="left">
            <el-form-item :label="$t('region.nom')" prop="nom">
              <el-input v-model="currentRegion.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentRegion.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateRegion=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="regionCreating" @click="editing ? updateRegion() : createRegion()">
              {{ regionCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :title="detailDataM !== null? 'Détails de la région : '+ detailDataM.nom: 'Détails'"
        :visible.sync="detailM"
        :before-close="handleCloseRegionCreateDraw"
        :loading="regionCreating"
        width="30%"
      >
        <div v-if="detailDataM !== null" class="content-inputs">
          <el-form ref="regionForm" :rules="rules" :model="detailDataM" label-position="left">
            <el-form-item :label="$t('region.nom')" prop="nom">
              <el-input v-model="detailDataM.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="detailDataM.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="info" @click="detailM=false">
              {{ $t('permission.cancel') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import TopCount from '@/views/localites/TopCount';
const regionResource = new Resource('regions');
export default {
  name: 'RegionsList',
  components: { Pagination, TopCount },
  directives: { waves, permission, role },
  props: {
    regionsProps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      regionCreating: false,
      editing: false,
      detailM: false,
      detailDataM: null,
      activeList: 'disponibles',
      query: {
        page: 1,
        limit: 15,
        keyword: '',
      },
      currentRegion: {},
      dialogCreateRegion: false,
      currentRegionId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('region.NomRequired'), trigger: 'blur' }],
        // description: [{ required: true, message: this.$t('region.DescriptionRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    this.resetNewRegion();
    this.list = this.regionsProps; // this.getRegionsList();
    this.getRegionsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseRegionCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelRegionForm() {
      this.$refs.RegionCreationDrawer.closeDrawer();
    },
    showDetailsModal(row){
      this.detailDataM = row;
      this.detailM = true;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterRegions();
    },
    async getRegionsList() {
      this.loading = true;
      const { data, meta } = await regionResource.sublist('?typeApi="all"', this.query);
      this.list = data;
      this.total = meta !== undefined ? meta.total : 0;
      // const { limit, page } = this.query;
      // const { data, meta } = await regionResource.list(this.query);
      /* this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      }); */
      // this.total = meta.total;
      this.loading = false;
    },
    async getSubRegionsList(subUrl) {
      this.loading = true;
      const { data } = await regionResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterRegions() {
      this.query.page = 1;
      this.getRegionsList();
    },
    handleCreateRegion() {
      this.editing = false;
      this.resetNewRegion();
      this.dialogCreateRegion = true;
      this.$nextTick(() => {
        this.$refs['regionForm'].clearValidate();
      });
    },
    handleEditRegion(id){
      this.editing = true;
      this.resetNewRegion();
      this.currentRegion = this.list.find(abonne => abonne.id === id);
      this.dialogCreateRegion = true;
      this.$nextTick(() => {
        this.$refs['regionForm'].clearValidate();
      });
    },
    async setRegionStatus(id, type){
      const data = await regionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteRegion(id, visible, name) {
      this.$confirm(this.$t('region.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer region']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              regionResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('region.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setRegionStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createRegion() {
      this.$refs['regionForm'].validate((valid) => {
        if (valid) {
          this.currentRegion.roles = [this.currentRegion.role];
          this.regionCreating = true;
          regionResource
            .store(this.currentRegion)
            .then(async(response) => {
              this.$message({
                message: this.$t('region.NewRegion') + ' ' + this.currentRegion.nom + ' ' + this.$t('region.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentRegionId = response.data.id;
              this.resetNewRegion();
              this.dialogCreateRegion = false;
              this.handleFilterRegions();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.regionCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    updateRegion() {
      this.$refs['regionForm'].validate((valid) => {
        if (valid) {
          this.regionCreating = true;
          regionResource.update(this.currentRegion.id, this.currentRegion).then(response => {
            this.resetNewRegion();
            this.handleFilterRegions();
            this.$message({
              message: this.$t('region.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateRegion = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.regionCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    resetNewRegion() {
      this.currentRegion = {
        nom: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('region.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des régions SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
