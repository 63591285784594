<template>
  <div class="app-container">
    <!--div class="page-title">
      <h2>{{ $t('commune.communes') }}</h2>
    </div-->
    <top-count title="Total Account" :end-val="total" />
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="actives" border @change="getSubCommunesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
        <el-radio v-model="activeList" label="desactives" border @change="getSubCommunesList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterCommunes">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubCommunesList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-select v-model="query.zone_id" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('zone.zone')" style="width: 100%;" @change="initZoneFilter">
          <el-option v-for="(zone, index) in zones" :key="index" :label="zone.nom | uppercaseFirst" :value="zone.id" />
        </el-select>
        <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @input="handleFilterCommunes" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterCommunes">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer commune'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateCommune">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" :label="$t('zone.zone')" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.zone_nom }}</span>
          </template>
        </el-table-column>

        <!-- el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column -->

        <el-table-column align="center" :label="$t('general.nom')">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!='corbeille' && checkPermission(['activer commune'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              :disabled="!scope.row.is_visible"
              @change="setCommuneStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if=" checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setCommuneStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['modifier commune','visible commune','supprimer commune'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier commune'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditCommune(scope.row.id);" />
            <el-button v-if="checkPermission(['visible commune','supprimer commune'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteCommune(scope.row.id, scope.row.is_visible, scope.row.nom);" />
            <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsModal(scope.row)" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getCommunesList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('commune.edit') + ' ' + currentCommune.nom : $t('commune.AddCommune')"
        :visible.sync="dialogCreateCommune"
        :before-close="handleCloseCommuneCreateDraw"
        :loading="communeCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="communeForm" :rules="rules" :model="currentCommune" label-position="left">
            <el-form-item :label="$t('zone.zone')" prop="zone_id">
              <el-select v-model="currentCommune.zone_id" class="filter-item" :placeholder="$t('zone.zone')" style="width: 100%;" prop="zone_id">
                <el-option
                  v-for="(zone, idex) in zones"
                  :key="idex"
                  :label="zone.nom | uppercaseFirst"
                  :value="zone.id"
                  :disabled="!zone.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('commune.nom')" prop="nom">
              <el-input v-model="currentCommune.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentCommune.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateCommune=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="communeCreating" @click="editing ? updateCommune() : createCommune()">
              {{ communeCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :title="detailDataM !== null? 'Détails de la commune : '+ detailDataM.nom: 'Détails'"
        :visible.sync="detailM"
        :before-close="handleCloseCommuneCreateDraw"
        :loading="communeCreating"
        width="30%"
      >
        <div v-if="detailDataM !== null" class="content-inputs">
          <el-form ref="communeForm" :rules="rules" :model="detailDataM" label-position="left">
            <el-form-item :label="$t('zone.zone')" prop="zone_id">
              <el-select v-model="detailDataM.zone_id" class="filter-item" :placeholder="$t('zone.zone')" style="width: 100%;" prop="zone_id">
                <el-option
                  v-for="(zone, idex) in zones"
                  :key="idex"
                  :label="zone.nom | uppercaseFirst"
                  :value="zone.id"
                  :disabled="!zone.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('commune.nom')" prop="nom">
              <el-input v-model="detailDataM.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="detailDataM.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="info" @click="detailM=false">
              {{ $t('permission.cancel') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import TopCount from '@/views/localites/TopCount';
const communeResource = new Resource('communes');
const zoneResource = new Resource('zones');
export default {
  name: 'CommunesList',
  components: { Pagination, TopCount },
  directives: { waves, permission, role },
  props: {
    zonesProps: {
      type: Array,
      default: () => [],
    },
    communesProps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      zones: {},
      nomZone: {},
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      communeCreating: false,
      detailM: false,
      detailDataM: null,
      editing: false,
      activeList: 'disponibles',
      query: {
        page: 1,
        limit: 100,
        keyword: '',
        zone_id: '',
      },
      currentCommune: {},
      dialogCreateCommune: false,
      currentCommuneId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('commune.NomRequired'), trigger: 'blur' }],
        // description: [{ required: true, message: this.$t('commune.DescriptionRequired'), trigger: 'blur' }],
        zone_id: [{ required: true, message: this.$t('commune.ZoneRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    // this.zones = this.zonesProps; // this.getZonesList();
    // this.formatToObject(this.zonesProps);
    this.resetNewCommune();
    // this.list = this.communesProps; // this.getCommunesList();
    this.getCommunesList();
    this.getZonesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    getZoneName(zoneId){
      const found = this.zones.find(zone => zone.id === zoneId);
      if (found) {
        return found.userscount;
      } else {
        return '';
      }
    },
    handleCloseCommuneCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelCommuneForm() {
      this.$refs.CommuneCreationDrawer.closeDrawer();
    },
    showDetailsModal(row){
      this.detailDataM = row;
      this.detailM = true;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        zone_id: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterCommunes();
    },
    initZoneFilter(){
      this.activeList = 'disponibles';
      this.handleFilterCommunes();
    },
    async getZonesList() {
      const { data } = await zoneResource.sublist('?typeApi="all"', this.query);
      this.zones = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomZone = _reg;
    },
    formatToObject(_arrays){
      const _reg = [];
      _arrays.forEach(_array => {
        _reg[_array.id] = _array.nom;
      });
      this.nomZone = _reg;
    },
    async getCommunesList() {
      this.loading = true;
      const { data, meta } = await communeResource.sublist('?typeApi="all"', this.query);
      this.list = data;
      this.total = meta !== undefined ? meta.total : 0;
      // const { limit, page } = this.query;
      // const { data, meta } = await communeResource.list(this.query);
      /* this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      }); */
      // this.total = meta.total;
      this.loading = false;
    },
    async getSubCommunesList(subUrl) {
      this.loading = true;
      const { data } = await communeResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterCommunes() {
      this.query.page = 1;
      this.getCommunesList();
      this.getZonesList();
    },
    handleCreateCommune() {
      this.editing = false;
      this.resetNewCommune();
      this.dialogCreateCommune = true;
      this.$nextTick(() => {
        this.$refs['communeForm'].clearValidate();
      });
    },
    handleEditCommune(id){
      this.editing = true;
      this.resetNewCommune();
      this.currentCommune = this.list.find(abonne => abonne.id === id);
      this.dialogCreateCommune = true;
      this.$nextTick(() => {
        this.$refs['communeForm'].clearValidate();
      });
    },
    async setCommuneStatus(id, type){
      const data = await communeResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteCommune(id, visible, name) {
      this.$confirm(this.$t('commune.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer commune']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              communeResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('commune.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setCommuneStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createCommune() {
      this.$refs['communeForm'].validate((valid) => {
        if (valid) {
          this.currentCommune.roles = [this.currentCommune.role];
          this.communeCreating = true;
          communeResource
            .store(this.currentCommune)
            .then(async(response) => {
              this.$message({
                message: this.$t('commune.NewCommune') + ' ' + this.currentCommune.nom + ' ' + this.$t('commune.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentCommuneId = response.data.id;
              this.resetNewCommune();
              this.dialogCreateCommune = false;
              this.handleFilterCommunes();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.communeCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    updateCommune() {
      this.$refs['communeForm'].validate((valid) => {
        if (valid) {
          this.communeCreating = true;
          communeResource.update(this.currentCommune.id, this.currentCommune).then(response => {
            this.resetNewCommune();
            this.handleFilterCommunes();
            this.$message({
              message: this.$t('commune.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateCommune = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.communeCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    resetNewCommune() {
      this.currentCommune = {
        nom: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('commune.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des communes SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
