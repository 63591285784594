/** On peut décomposer les routes par module**/
import Layout from '@/layout';

const adminRoutes = {
  path: '/administration',
  component: Layout,
  redirect: '/administration/users',
  name: 'Administration',
  // alwaysShow: true,
  meta: {
    title: 'administration',
    icon: 'el-icon-s-tools',
    permissions: ['gerer user', 'gerer permission'],
  },
  children: [
    /** Gestion des utilisateurs */
    /** {
      path: 'users/edit/:id(\\d+)',
      component: () => import('@/views/users/UserProfile'),
      name: 'UserProfile',
      meta: { title: 'userProfile', noCache: true, permissions: ['gerer user'] },
      hidden: true,
    }, */
    {
      path: 'users',
      component: () => import(/* webpackChunkName: "users" */ '@/views/users/List'),
      name: 'UserList',
      meta: { title: 'users', icon: 'el-icon-user-solid', permissions: ['gerer user'] },
    },
    /** Roles et permissions */
    {
      path: 'roles',
      component: () => import(/* webpackChunkName: "roles" */ '@/views/role-permission/List'),
      name: 'RoleList',
      meta: { title: 'rolePermission', icon: 'el-icon-key', permissions: ['gerer permission'] },
    },
    /** Visa Resp. Filière */
    {
      path: 'visa-resp-fil',
      component: () => import(/* webpackChunkName: "roles" */ '@/views/visas/visaRespFil'),
      name: 'VisaRespFil',
      meta: { title: 'visaRespFiliere', icon: 'el-icon-edit-outline', permissions: [] },
    },
    /** Visa Chargé Shipping*/
    {
      path: 'visa-charge-shipping',
      component: () => import(/* webpackChunkName: "roles" */ '@/views/visas/visaChargeShipping'),
      name: 'VisaChargeShipping',
      meta: { title: 'Visa Chargé Shipping', icon: 'el-icon-edit-outline', permissions: [] },
    },
    /** Visa Chargée Normes & Procédures*/
    {
      path: 'visa-charge-np',
      component: () => import(/* webpackChunkName: "roles" */ '@/views/visas/visaChargeNP'),
      name: 'VisaChargeNP',
      meta: { title: 'Visa Chargé N & P', icon: 'el-icon-edit-outline', permissions: [] },
    },
  ],
};

export default adminRoutes;
