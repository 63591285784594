<template>
  <div class="app-container">
    <!--div class="page-title">
      <h2>{{ $t('zone.zones') }}</h2>
    </div-->
    <top-count title="Total Account" :end-val="total" />
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="actives" border @change="getSubZonesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
        <el-radio v-model="activeList" label="desactives" border @change="getSubZonesList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterZones">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubZonesList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-select v-model="query.region_id" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('region.region')" style="width: 100%;" @change="initRegionFilter">
          <el-option v-for="(region, index) in regions" :key="index" :label="region.nom | uppercaseFirst" :value="region.id" />
        </el-select>
        <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @input="handleFilterZones" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterZones">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer zone'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateZone">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" :label="$t('region.region')" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.region_nom }}</span>
          </template>
        </el-table-column>

        <!-- el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column -->

        <el-table-column align="center" :label="$t('general.nom')">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!='corbeille' && checkPermission(['activer zone'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              :disabled="!scope.row.is_visible"
              @change="setZoneStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if=" checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setZoneStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['modifier zone','visible zone','supprimer zone'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier zone'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditZone(scope.row.id);" />
            <el-button v-if="checkPermission(['visible zone','supprimer zone'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteZone(scope.row.id, scope.row.is_visible, scope.row.nom);" />
            <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsModal(scope.row)" />
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getZonesList" /-->

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('zone.edit') + ' ' + currentZone.nom : $t('zone.AddZone')"
        :visible.sync="dialogCreateZone"
        :before-close="handleCloseZoneCreateDraw"
        :loading="zoneCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="zoneForm" :rules="rules" :model="currentZone" label-position="left">
            <el-form-item :label="$t('region.region')" prop="region_id">
              <el-select v-model="currentZone.region_id" class="filter-item" :placeholder="$t('region.region')" style="width: 100%;" prop="region_id">
                <el-option
                  v-for="(region, idex) in regions"
                  :key="idex+2"
                  :label="region.nom | uppercaseFirst"
                  :value="region.id"
                  :disabled="!region.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('zone.nom')" prop="nom">
              <el-input v-model="currentZone.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentZone.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateZone=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="zoneCreating" @click="editing ? updateZone() : createZone()">
              {{ zoneCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :title="detailDataM !== null? 'Détails de la région : '+ detailDataM.nom: 'Détails'"
        :visible.sync="detailM"
        :before-close="handleCloseZoneCreateDraw"
        :loading="zoneCreating"
        width="30%"
      >
        <div v-if="detailDataM !== null" class="content-inputs">
          <el-form ref="zoneForm" :rules="rules" :model="detailDataM" label-position="left">
            <el-form-item :label="$t('region.region')" prop="region_id">
              <el-select v-model="detailDataM.region_id" class="filter-item" :placeholder="$t('region.region')" style="width: 100%;" prop="region_id">
                <el-option
                  v-for="(region, idex) in regions"
                  :key="idex"
                  :label="region.nom | uppercaseFirst"
                  :value="region.id"
                  :disabled="!region.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('zone.nom')" prop="nom">
              <el-input v-model="detailDataM.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="detailDataM.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="info" @click="detailM=false">
              {{ $t('permission.cancel') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import TopCount from '@/views/localites/TopCount';
const zoneResource = new Resource('zones');
const regionResource = new Resource('regions');
export default {
  name: 'ZonesList',
  components: { TopCount },
  directives: { waves, permission, role },
  props: {
    regionsProps: {
      type: Array,
      default: () => [],
    },
    zoneProps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      regions: {},
      nomRegion: {},
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      zoneCreating: false,
      detailM: false,
      detailDataM: null,
      editing: false,
      activeList: 'disponibles',
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        region_id: '',
      },
      currentZone: {},
      dialogCreateZone: false,
      currentZoneId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('zone.NomRequired'), trigger: 'blur' }],
        description: [{ required: true, message: this.$t('zone.DescriptionRequired'), trigger: 'blur' }],
        region_id: [{ required: true, message: this.$t('zone.RegionRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    // this.regions = this.regionsProps;
    // this.formatToObject(this.regionsProps);
    this.getRegionsList();
    this.resetNewZone();
    this.getZonesList();
    // this.list = this.zoneProps;
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    getRegionName(regionId){
      const found = this.regions.find(region => region.id === regionId);
      if (found) {
        return found.userscount;
      } else {
        return '';
      }
    },
    handleCloseZoneCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    showDetailsModal(row){
      this.detailDataM = row;
      this.detailM = true;
    },
    cancelZoneForm() {
      this.$refs.ZoneCreationDrawer.closeDrawer();
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        region_id: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterZones();
    },
    initRegionFilter(){
      this.activeList = 'disponibles';
      this.handleFilterZones();
    },
    async getRegionsList() {
      const { data } = await regionResource.sublist('?typeApi="all"', this.query);
      this.regions = data;
      const _reg = [];
      data.forEach(region => {
        _reg[region.id] = region.nom;
      });
      this.nomRegion = _reg;
    },
    formatToObject(_arrays){
      const _reg = [];
      _arrays.forEach(_array => {
        _reg[_array.id] = _array.nom;
      });
      this.nomRegion = _reg;
    },
    async getZonesList() {
      this.loading = true;
      const { meta, data } = await zoneResource.sublist('?typeApi="all"', this.query);
      this.total = meta.total;
      this.list = data;
      // const { limit, page } = this.query;
      // const { data, meta } = await zoneResource.list(this.query);
      /* this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      }); */
      // this.total = meta.total;
      this.loading = false;
    },
    async getSubZonesList(subUrl) {
      this.loading = true;
      const { data } = await zoneResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterZones() {
      this.query.page = 1;
      this.getZonesList();
      this.getRegionsList();
    },
    handleCreateZone() {
      this.editing = false;
      this.resetNewZone();
      this.dialogCreateZone = true;
      this.$nextTick(() => {
        this.$refs['zoneForm'].clearValidate();
      });
    },
    handleEditZone(id){
      this.editing = true;
      this.resetNewZone();
      this.currentZone = this.list.find(abonne => abonne.id === id);
      this.dialogCreateZone = true;
      this.$nextTick(() => {
        this.$refs['zoneForm'].clearValidate();
      });
    },
    async setZoneStatus(id, type){
      const data = await zoneResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteZone(id, visible, name) {
      this.$confirm(this.$t('zone.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer zone']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              zoneResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('zone.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setZoneStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createZone() {
      this.$refs['zoneForm'].validate((valid) => {
        if (valid) {
          this.currentZone.roles = [this.currentZone.role];
          this.zoneCreating = true;
          zoneResource
            .store(this.currentZone)
            .then(async(response) => {
              this.$message({
                message: this.$t('zone.NewZone') + ' ' + this.currentZone.nom + ' ' + this.$t('zone.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentZoneId = response.data.id;
              this.resetNewZone();
              this.dialogCreateZone = false;
              this.handleFilterZones();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.zoneCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    updateZone() {
      this.$refs['zoneForm'].validate((valid) => {
        if (valid) {
          this.zoneCreating = true;
          zoneResource.update(this.currentZone.id, this.currentZone).then(response => {
            this.resetNewZone();
            this.handleFilterZones();
            this.$message({
              message: this.$t('zone.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateZone = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.zoneCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    resetNewZone() {
      this.currentZone = {
        nom: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('zone.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des zones SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
