import Vue from 'vue';
import Router from 'vue-router';

/**
 * Layzloading will create many files and slow on compiling, so best not to use lazyloading on devlopment.
 * The syntax is lazyloading, but we convert to proper require() with babel-plugin-syntax-dynamic-import
 * @see https://doc.sciagri.dev/guide/advanced/lazy-loading.html
 */

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router for modules */
import adminRoutes from './modules/admin';
import errorRoutes from './modules/error';
// import uniques from './modules/uniques';

/**
 * Sub-menu only appear when children.length>=1
 * @see https://doc.sciagri.dev/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin', 'editor']   Visible for these roles only
    permissions: ['voir menu zip', 'manage user'] Visible for these permissions only
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb (default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index'),
    hidden: true,
  },
  {
    path: '/auth-redirect',
    component: () => import(/* webpackChunkName: "auth-redirect" */ '@/views/login/AuthRedirect'),
    hidden: true,
  },
  {
    path: '/tuto',
    component: () => import(/* webpackChunkName: "401" */ '@/views/tuto/index'),
    hidden: true,
  },
  {
    path: '/demande-suppression-compte',
    component: () => import(/* webpackChunkName: "401" */ '@/views/supprimercompte/index'),
    hidden: true,
  },
  {
    path: '/404',
    redirect: { name: 'Page404' },
    component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401'),
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    redirect: { name: 'Accueil' },
    name: 'Dashboard',
    meta: { title: 'dashboard', icon: 'el-icon-s-home', noCache: false },

    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index'),
        name: 'Accueil',
        meta: { title: 'Accueil' },
      },
    ],
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/users/index'),
        name: 'Profile',
        meta: { title: 'Profile', icon: 'user', noCache: true },
      },
    ],
  },
  {
    path: '/bureau',
    component: Layout,
    // redirect: 'dashboard',
    name: 'Bureau',
    meta: {
      title: 'Bureau',
      icon: 'el-icon-data-analysis',
    },
    children: [
      // {
      //   path: 'dashboard',
      //   component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index'),
      //   name: 'Dashboard',
      //   meta: { title: 'dashboard', icon: 'el-icon-s-home', noCache: false },
      // },
      /** Regions */
      {
        path: 'localites',
        component: () => import('@/views/localites/index'),
        name: 'Localites',
        meta: { title: 'localites', icon: 'el-icon-location', permissions: ['gerer region', 'gerer zone', 'gerer village', 'gerer commune'] },
      },
      /** Campagne agricole */
      {
        path: 'campagnes-agricoles',
        component: () => import(/* webpackChunkName: "campagnes-agricoles" */ '@/views/campagne-agricoles/List'),
        name: 'CompagneAgricoles',
        meta: { title: 'campagneagricoles', icon: 'el-icon-folder-opened', permissions: ['gerer campagneagricole'] },
      },
      {
        path: 'produits',
        component: () => import(/* webpackChunkName: "produits" */ '@/views/produits/List'),
        name: 'Produits',
        meta: { title: 'Produits', icon: 'el-icon-goods' },
      },
      // {
      //   path: 'produit',
      //   component: () => import(/* webpackChunkName: "producteurUnique" */ '@/views/produits/Unique'),
      //   name: 'Produit',
      //   meta: { title: 'Produit', icon: 'el-icon-user', noCache: false },
      //   props: true,
      //   hidden: true,
      // },
      {
        path: 'unitesProductions',
        component: () => import(/* webpackChunkName: "unitesProductions" */ '@/views/unite-productions/List'),
        name: 'UnitesProduction',
        meta: { title: 'Unités de production', icon: 'el-icon-cpu' },
      },
      {
        path: '/uniteProduction/:id(\\d+)',
        component: () => import(/* webpackChunkName: "uniteProductionUnique" */ '@/views/unite-productions/UniquePage'),
        name: 'uniteProduction',
        meta: { title: 'Unité de production', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: 'categoriesQuestions',
        component: () => import(/* webpackChunkName: "categoriesQuestions" */ '@/views/categorie-questions/List'),
        name: 'CategoriesQuestions',
        meta: { title: 'Catégories des questions', icon: 'el-icon-question' },
      },
      {
        path: '/categorieQuestions/:id(\\d+)',
        component: () => import(/* webpackChunkName: "categorieQuestionsUnique" */ '@/views/categorie-questions/UniquePage'),
        name: 'categorieQuestions',
        meta: { title: 'Catégorie des questions', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: 'questions',
        component: () => import(/* webpackChunkName: "questions" */ '@/views/questions/List'),
        name: 'Questions',
        meta: { title: 'Questions', icon: 'el-icon-chat-dot-square', permissions: ['gerer questions'] },
      },
      {
        path: 'groupements',
        component: () => import(/* webpackChunkName: "groupements" */ '@/views/groupement/List'),
        name: 'Groupements',
        meta: { title: 'Groupements', icon: 'el-icon-s-order' },
      },
      {
        path: '/groupement/:id(\\d+)',
        component: () => import(/* webpackChunkName: "GroupementUnique" */ '@/views/groupement/UniquePage'),
        name: 'Groupement',
        meta: { title: 'Groupement', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: 'grades',
        component: () => import(/* webpackChunkName: "gradeControleurs" */ '@/views/grade-controleur/List'),
        name: 'Grades',
        meta: { title: 'Grades', icon: 'el-icon-files' },
      },
      {
        path: '/grade/:id(\\d+)',
        component: () => import(/* webpackChunkName: "GradeUnique" */ '@/views/grade-controleur/UniquePage'),
        name: 'Grade',
        meta: { title: 'Grade', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: 'controleurs',
        component: () => import(/* webpackChunkName: "Controleurs" */ '@/views/controleurs/List'),
        name: 'Controleurs',
        meta: { title: 'Controleurs', icon: 'el-icon-s-custom' },
      },
      {
        path: '/controleur/:id(\\d+)',
        component: () => import(/* webpackChunkName: "ControleurUnique" */ '@/views/controleurs/UniquePage'),
        name: 'Controleur',
        meta: { title: 'Controleur', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      // {
      //  path: 'typeMedias',
      //  component: () => import(/* webpackChunkName: "typeMedias" */ '@/views/type-media/List'),
      //  name: 'TypeMedias',
      //  meta: { title: 'Type de Médias', icon: 'el-icon-video-camera-solid' },
      // },
      {
        path: 'structures',
        component: () => import(/* webpackChunkName: "structures" */ '@/views/structure/List'),
        name: 'Structures',
        meta: { title: 'Structures', icon: 'el-icon-s-flag' },
      },
      {
        path: '/structure/:id(\\d+)',
        component: () => import(/* webpackChunkName: "StructureUnique" */ '@/views/structure/UniquePage'),
        name: 'Structure',
        meta: { title: 'Structure', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: 'formations',
        component: () => import(/* webpackChunkName: "formations" */ '@/views/formation/List'),
        name: 'Formations',
        meta: { title: 'Formations', icon: 'el-icon-s-order' },
      },
      {
        path: '/formations/:id(\\d+)',
        component: () => import(/* webpackChunkName: "FormationsUnique" */ '@/views/formation/UniquePage'),
        name: 'Formationid',
        meta: { title: 'Formation', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: '/notifications',
        component: () => import(/* webpackChunkName: "FormationsUnique" */ '@/views/notifications/List'),
        name: 'Notifications',
        meta: { title: 'Notifications', icon: 'el-icon-bell', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      // {
      //   path: 'bls',
      //   component: () => import(/* webpackChunkName: "bls" */ '@/views/bls/List'),
      //   name: 'Bls',
      //   meta: { title: 'Bls', icon: 'el-icon-s-order' },
      // },
    ],
  },
  // {
  //   path: '/impressions/:id(\\d+)',
  //   component: () => import(/* webpackChunkName: "impressionproduit" */ '@/views/produits/Unique'),
  //   name: 'impressionProduit',
  //   meta: { title: 'impressionProduit', noCache: true },
  //   hidden: true,
  // },
  {
    path: '/produit/:id(\\d+)',
    component: () => import(/* webpackChunkName: "produitUnique" */ '@/views/produits/Unique'),
    name: 'Produit',
    // meta: { title: 'Produit', icon: 'el-icon-user', noCache: false },
    props: true,
    hidden: true,
  },
  {
    path: '/producteur/:id(\\d+)',
    component: () => import(/* webpackChunkName: "ProducteurUnique" */ '@/views/producteurs/Unique'),
    name: 'Producteur',
    meta: { title: 'Producteur', icon: 'el-icon-user', noCache: false },
    hidden: true,
  },
  {
    path: '/parcelle/:id(\\w+)',
    component: () => import(/* webpackChunkName: "parcelleUnique" */ '@/views/parcelles/Unique'),
    name: 'parcelle',
    meta: { title: 'Parcelle', icon: 'el-icon-user', noCache: false },
    hidden: true,
  },
  // {
  // path: '/shipping',
  // component: Layout,
  // redirect: 'dashboard',
  // name: 'Shipping',
  // meta: {
  // title: 'Shipping',
  // icon: 'el-icon-truck',
  // },
  // children: [
  // {
  // path: 'dashboard',
  // component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index'),
  // name: 'Dashboard',
  // meta: { title: 'dashboard', icon: 'el-icon-s-home', noCache: false },
  // },
  // ],
  // },
  {
    path: '/terrain',
    component: Layout,
    // redirect: 'producteur',
    alwaysShow: true,
    name: 'Terrain',
    meta: {
      title: 'Terrain',
      icon: 'el-icon-guide',
    },
    children: [
      {
        path: 'producteurs',
        component: () => import(/* webpackChunkName: "producteur" */ '@/views/producteurs/List'),
        name: 'Producteurs',
        meta: { title: 'Producteurs', icon: 'el-icon-user', noCache: false },
      },
      {
        path: '/producteurs/:id(\\d+)',
        component: () => import(/* webpackChunkName: "ProducteursUnique" */ '@/views/producteurs/UniquePage'),
        name: 'Producteurid',
        meta: { title: 'Producteur', icon: 'el-icon-user', noCache: false },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: '/parcelles/:code',
        component: () => import(/* webpackChunkName: "ParcellesUnique" */ '@/views/parcelles/UniquePage'),
        name: 'codeParcelle',
        meta: { title: 'Parcelle', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      // {
      //   path: 'conteneur',
      //   component: () => import(/* webpackChunkName: "conteneur" */ '@/views/conteneurs/List'),
      //   name: 'Conteneur',
      //   meta: { title: 'conteneur', icon: 'el-icon-s-home', noCache: false },
      // },
      // {
      //   path: 'operationCulturales',
      //   component: () => import(/* webpackChunkName: "operationCulturales" */ '@/views/operationCulturales/List'),
      //   name: 'operationCulturales',
      //   meta: { title: 'Opérations Culturales', icon: 'el-icon-s-home', noCache: false },
      // },
      {
        path: 'parcelles',
        component: () => import(/* webpackChunkName: "parcelles" */ '@/views/parcelles/List'),
        name: 'parcelles',
        meta: { title: 'Parcelles', icon: 'el-icon-map-location', noCache: false },
      },
    ],
  },
  {
    path: '/collecte',
    component: Layout,
    alwaysShow: true,
    name: 'Collecte',
    meta: {
      title: 'Collecte',
      icon: 'el-icon-truck',
      permissions: ['insert data'],
    },
    children: [
      {
        path: 'recoltes',
        component: () => import(/* webpackChunkName: "recoltes" */ '@/views/recolte/List'),
        name: 'recoltes',
        meta: { title: 'Collectes', icon: 'el-icon-truck', noCache: false },
      },
      {
        path: '/recoltes/:id(\\w+)',
        component: () => import(/* webpackChunkName: "lotsUnique" */ '@/views/recolte/UniquePage'),
        name: 'RecolteId',
        meta: { title: 'Collecte', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: 'produit-collecte',
        component: () => import(/* webpackChunkName: "conteneur" */ '@/views/produit_collecte/List'),
        name: 'ProduitRecoltes',
        meta: { title: 'Produit collecté', icon: 'el-icon-sell', noCache: false },
      },
      {
        path: 'periode-collecte',
        component: () => import(/* webpackChunkName: "conteneur" */ '@/views/periode_collecte/List'),
        name: 'PériodeCollecte',
        meta: { title: 'Période Collecte', icon: 'el-icon-timer', noCache: false },
      },
      {
        path: 'lots',
        component: () => import(/* webpackChunkName: "lots" */ '@/views/lots/List'),
        name: 'Lots',
        meta: { title: 'Lots', icon: 'el-icon-truck', noCache: false },
      },
      {
        path: '/lots/:id(\\w+)',
        component: () => import(/* webpackChunkName: "lotsUnique" */ '@/views/lots/UniquePage'),
        name: 'LotId',
        meta: { title: 'Lot', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
      {
        path: 'conteneurs',
        component: () => import(/* webpackChunkName: "conteneur" */ '@/views/conteneurs/List'),
        name: 'Conteneurs',
        meta: { title: 'Conteneurs', icon: 'el-icon-truck', noCache: false },
      },
      {
        path: '/conteneurs/:id(\\w+)',
        component: () => import(/* webpackChunkName: "lotsUnique" */ '@/views/conteneurs/UniquePage'),
        name: 'Conteneur',
        meta: { title: 'Conteneur', icon: 'el-icon-user', noCache: true },
        hidden: true,
        breadcrumb: false,
      },
    ],
  },
  {
    path: '/donnee',
    component: Layout,
    alwaysShow: true,
    name: 'Donnees',
    meta: {
      title: 'Donnees',
      icon: 'el-icon-pie-chart',
      permissions: ['insert data'],
    },
    children: [
      {
        path: 'insertion',
        component: () => import(/* webpackChunkName: "formations" */ '@/views/Insertion/index'),
        name: 'Importation',
        meta: { title: 'Importation', icon: 'el-icon-download' },
      },
      {
        path: 'exportation',
        component: () => import(/* webpackChunkName: "formations" */ '@/views/Insertion/exportation'),
        name: 'Exportation',
        meta: { title: 'Exportation', icon: 'el-icon-upload' },
      },
    ],
  },
  // {
  //  path: '/shipping',
  //  component: Layout,
  // redirect: 'producteur',
  //  alwaysShow: true,
  //  name: 'Shipping',
  //  meta: {
  //    title: 'Shipping',
  //    icon: 'el-icon-ship',
  //  },
  //  children: [
  //    {
  //      path: '/lots/:id(\\w+)',
  //      component: () => import(/* webpackChunkName: "lotsUnique" */ '@/views/lots/UniquePage'),
  //      name: 'LotId',
  //      meta: { title: 'Lot', icon: 'el-icon-user', noCache: true },
  //      hidden: true,
  //      breadcrumb: false,
  //    },
  //  ],
  // },
];

export const asyncRoutes = [
  adminRoutes,
  errorRoutes,
  // uniques,
  { path: '*', redirect: '/404', hidden: true },
];

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  base: '/',
  routes: constantRoutes,
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
