import '@/styles/index.scss';
import 'core-js';
import '@/icons'; // icon
import Vue from 'vue';
import Cookies from 'js-cookie';
import ElementUI from 'element-ui';
import App from './views/App';
import store from './store';
import router from '@/router';
import i18n from './lang'; // Internationalization
import '@/permission'; // permission control
import Vuesax from 'vuesax';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

import 'vuesax/dist/vuesax.css'; // Vuesax styles
Vue.use(Vuesax);

import elEmpty from '@/components/empty';
Vue.component('el-empty', elEmpty);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import * as filters from './filters'; // global filters
import './registerServiceWorker';

Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value),
});

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// VARS
Vue.prototype.$limit = 100;

Vue.config.productionTip = false;

// COMPOSANTS LOCALITES
Vue.component(
  'regions',
  require('./views/localites/regions/List.vue').default
);
Vue.component(
  'zones',
  require('./views/localites/zones/List.vue').default
);
Vue.component(
  'communes',
  require('./views/localites/communes/List.vue').default
);
Vue.component(
  'villages',
  require('./views/localites/villages/List.vue').default
);
// COMPOSANTS LOCALITES

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App),
});
