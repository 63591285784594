export default {
  general: {
    next: 'Suivant',
    yes: 'Oui',
    no: 'Non',
    send: 'Envoyer',
    description: 'Description',
    code: 'Code',
    nom: 'Nom',
    libelle: 'Libelle',
    title: 'Titre',
    type: 'Type',
    search: 'Chercher',
    add: 'Ajouter',
    export: 'Exporter',
    id: 'ID',
    date: 'Date',
    author: 'Auteur',
    status: 'Statut',
    actions: 'Actions',
    edit: 'Modifier',
    delete: 'Supprimer',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    keyword: 'Mots clés',
    role: 'Role',
    permissions: 'Permissions',
    menus: 'Menus',
    rolesEtpermissions: 'Rôles et permissions',
    deleteWarningText: 'Êtes-vous sûr de vouloir supprimer ?',
    deleteConfirmButtonText: 'Oui supprimer',
    deleteCancelButtonText: 'Non laisser',
    save: 'Sauvegarder',
    saving: 'Sauvegarde ...',
    closingWarningText: 'Êtes-vous sûr de vouloir fermer ?',
    notFound: 'Aucun élément trouvé',
    actives: 'Actives',
    visible: 'Visible',
    disponibles: 'Disponibles',
    M_actives: 'Activés',
    F_actives: 'Activées',
    M_desactives: 'Désactivés',
    F_desactives: 'Désactivées',
    M_supprimes: 'Supprimés',
    F_supprimes: 'Supprimées',
    corbeille: 'Corbeille',
    restaurer: 'Restaurer',
    suppressionDefinitive: 'Suppression définitive',
    nonActives: 'Non Actives',
    nonVisibles: 'Non Visibles',
    F_actif: 'Active',
    M_actif: 'Actif',
    deleteContinue: 'Continuer ?',
    suppressionAnnule: 'Suppression annulée',
    filter: 'Filtrer',
    insert: 'Charger les données',
    init: 'Réinitialiser',
    statusUpdated: 'Mise à jour du statut éffectuée avec succès !',
    statusUpdateError: "Désolé, une erreur s'est produite lors de la mise à jour du status",
    ouiSupprimer: 'Oui supprimer',
    irrversibleMessage: 'Cette action est irréversible',
    SureQuestion: 'Etes-vous sûre ?',
    periode: 'Période',
    startDateplaceholder: 'Date de début',
    endDateplaceholder: 'Date de fin',
    DaterangeSeparator: 'à',
    ImapeUploadFieldText: 'Cliquer pour envoyer',
    clotures: 'Clôturés',
    labelColonneCloturer: 'clôturée',
    allpermissions: 'Toutes les permissions',
    formInvalid: 'Votre formulaire n\'est pas valide ! Veuillez vérifier les données !',
    print: 'Imprimer',
    load: 'Importer',
    otherInfos: 'Autres informations',
    reference: 'Référence',
    visaRespFiliere: 'Visa Responsable Filière',
    visaChargeShipping: 'Visa chargé shipping',
    visaChargeNP: 'Visa Chargé Normes & Procédures',
    error: 'Erreur',
    selectDateHeur: 'Selectionnez date et horaire',
  },
  insertion: {
    insert: 'Chargement des données',
  },
  route: {
    dashboard: 'Accueil',
    introduction: 'Introduction',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page de Permission',
    rolePermission: 'Role et Permission',
    visaRespFiliere: 'Visa Resp. Filière',
    icons: 'Icones',
    components: 'Composants',
    componentIndex: 'Introduction',
    errorPages: "Pages d'erreur",
    page401: '401',
    page404: '404',
    errorLog: 'Log',
    administration: 'Administration',
    users: 'Utilisateurs',
    userProfile: 'Profile Utilisateur',
    regions: 'Les régions',
    parcelles: 'Les parcelles',
    campagneagricoles: 'Campagnes agricoles',
    zones: 'Les Zones',
    localites: 'Les localités',
  },
  navbar: {
    logOut: 'Déconnexion',
    dashboard: 'Accueil',
    github: 'Github',
    theme: 'Theme',
    size: 'Taille globale',
    profile: 'Profile',
  },
  login: {
    title: 'Se connecter',
    logIn: 'Connecté',
    username: 'Identifiant',
    password: 'Mot de passe',
    email: 'Adresse email',
  },
  documentation: {
    documentation: 'Documentation',
    laravel: 'Laravel',
  },
  permission: {
    addRole: 'Nouveau Role',
    editPermission: 'Modifier la Permission',
    editPermissions: 'Modifier les Permissions',
    roles: 'Roles',
    switchRoles: 'Activer les roles',
    delete: 'Supprimer',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    permissionsUpdated: 'Les permissions ont bien été mises à jour !',
    extraPermissions: 'Permissions supplémentaires',
    addedPermissions: 'Permissions ajoutées',
    noSelectionAdd: 'Aucune permission ajoutée',
    manage: 'Gérer les permissions',
    addPermissions: 'Ajouter des permissions',
    tableName: 'Nom de la table',
    NameRequired: 'Veuillez saisir le nom',
    iscreatedSuccessfully: 'Permission ajoutée avec succès',
    name: 'Permission',
    suppressionEffectue: 'Permission supprimée avec succès',
  },
  guide: {
    description: 'Une description',
    button: 'Voir le Guide',
  },
  components: {
    documentation: 'Documentation',
  },
  excel: {
    export: 'Exporter',
    selectedExport: 'Exporter les éléments sélectionnés',
    placeholder: "Entrez le nom du fichier s'il vous plaît (par défaut : liste-excel)",
  },
  theme: {
    change: 'Changer le Theme',
    documentation: 'documentation du Theme',
    tips: "Conseils: Il est différent du choix de thème sur la barre de navigation, ce sont deux méthodes de skinning différentes, chacune avec des scénarios d'application différents.",
  },
  settings: {
    title: 'Configuration du style de Page',
    theme: 'Couleur du Theme',
    tagsView: 'Afficher les tags',
    fixedHeader: "Fixer la d'entête",
    sidebarLogo: 'Logo',
  },
  tagsView: {
    refresh: 'Rafraîchir',
    close: 'Fermer',
    closeOthers: 'Fermer les autres',
    closeAll: 'Fermer tout',
  },
  user: {
    role: 'Role',
    password: 'Mot de passe',
    c_password: 'Confirmer',
    nom: 'Nom',
    prenom: 'Prenom',
    email: 'Adresse Email',
    username: 'Identifiant',
    code: 'Code',
    EditPermissions: 'Modifier les permissions',
    AddUser: 'Ajouter un utilisateur',
    selectRole: 'Sélectionnez un rôle',
    PasswordsInconform: 'Les mots de passes ne sont pas conformes',
    RoleRequired: 'Veuillez sélectionner un rôle',
    NomRequired: 'Veuillez saisir le nom',
    EmailRequired: 'Veuillez saisir le mail',
    EmailInvalide: 'Veuillez saisir une adresse email valide',
    PasswordRequired: 'Veuillez entrer le mot de passe',
    deleteWarn: "Ceci supprimera l'utilisateur",
    deleteContinue: 'Continuer ?',
    cancel: 'Annuler',
    suppressionEffectue: 'Suppression effectuée avec succès!',
    suppressionAnnule: 'Suppression annulée',
    NewUser: 'Nouveau utilisateur',
    iscreatedSuccessfully: 'a été crée avec succès',
    PermissionsUpdated: 'Permissions mise à jour avec succès!',
    users: 'Utilisateurs',
    user: 'Utilisateur',
    updated: 'Profile mis à jour avec succès',
    edit: "Modifier l'utilisateur",
    PasswordMin6: 'Doit avoir au moins 6 caractères',
    isupdatedSuccessfully: "Mise à jour de l'utilisateur éffectuée avec succès !",
    changeCredentils: 'Modifier le mot de passe',
    changeAvatar: 'Changer l\'Avatar',
    nochangeCredentils: 'Ne pas modifier le mot de passe',
  },
  roles: {
    deleteWarn: 'Attention, ceci supprimera le role',
    permissions: 'Permissions',
    nom: 'Nom du rôle',
    users: 'Utilisateurs',
    extraMenus: 'Menus supplémentaires',
    InheritedfromRole: 'Hérité du rôle',
    addRole: 'Ajouter un rôle',
    name: 'Role',
    NewRole: 'Nouveau rôle',
    iscreatedSuccessfully: 'crée avec succès!',
    isupdatedSuccessfully: 'Le rôle a été mis à jour avec succès',
    NameRequired: 'Veillez saisir le rôle',
    edit: 'Modifier le rôle',
    suppressionEffectue: 'Rôle supprimé avec succès',
    description: {
      admin: "Le Super Administrateur administre l'application",
      manager: "Le Manager est le gérant de l'application",
      editeur: 'Utilisateur pouvant intégrer des données',
      utilisateur: 'Utilisateur normal',
      visiteur: 'les visiteurs ont juste un droit de visualisation',
    },
  },
  region: {
    region: 'Région',
    regions: 'Les régions',
    nom: 'Nom de la région',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom de la région',
    DescriptionRequired: 'Veuillez saisir un descriptif de la région',
    deleteWarn: 'Ceci supprimera la région',
    suppressionEffectue: 'Région supprimée avec succès',
    NewRegion: 'Nouvelle région',
    iscreatedSuccessfully: 'crée avec succès',
    isupdatedSuccessfully: 'Mise à jour de la région éffectuée avec succès !',
    created_at: 'Créee le',
    edit: 'Modifier la région',
    AddRegion: 'Nouvelle région',
    selectRegionTitle: 'Sélectionnez la région',
    selectOrCreateMessage: "Veuillez sélectionner la région s'il vous plaît. Si elle n'existe pas, cliquez sur le bouton (+) pour l'ajouter",
  },
  campagneagricole: {
    campagneagricoles: 'Les campagnes agricoles',
    nom: 'Nom de la campagne',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom de la campagne agricole',
    DescriptionRequired: 'Veuillez saisir un descriptif sur la campagne',
    deleteWarn: 'Ceci supprimera la campagne',
    suppressionEffectue: 'Campagne supprimée avec succès',
    NewCampagneagricole: 'Nouvelle campagne agricole',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de la campagne éffectuée avec succès !',
    created_at: 'Créee le',
    edit: 'Modifier la campagne',
    AddCampagneagricole: 'Nouvelle campagne agricole',
    FileCaracteristicsText: 'Fichiers jpg/png avec une taille inférieure à 500kb',
    CampaignBannerFieldText: 'Image Bannière',
    prevision: 'Prévision',
    periode: 'Période',
    previsionFieldRequiredWarningEmpty: 'la prévision est requise',
    previsionFieldRequiredWarningType: 'la prévision doit être un nombre',
    yearBeginPlaceholder: 'Année de début',
    yearEndPlaceholder: 'Année de fin',

  },
  zone: {
    zone: 'Zone',
    zones: 'Les zones',
    nom: 'Nom de la zone',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom de la zone',
    DescriptionRequired: 'Veuillez saisir un descriptif sur la zone',
    deleteWarn: 'Ceci supprimera la zone',
    suppressionEffectue: 'Zone supprimée avec succès',
    NewZone: 'Nouvelle Zone',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de la zone éffectuée avec succès !',
    created_at: 'Créee le',
    edit: 'Modifier la zone',
    AddZone: 'Nouvelle zone',
    RegionRequired: 'Vous devez sélectionner une région',
    selectZoneTitle: 'Sélectionnez la zone',
    selectOrCreateMessage: "Veuillez sélectionner la zone s'il vous plaît. Si elle n'existe pas, cliquez sur le bouton (+) pour l'ajouter",
  },
  commune: {
    commune: 'Commune',
    communes: 'Les communes',
    nom: 'Nom de la commune',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom de la commune',
    DescriptionRequired: 'Veuillez saisir un descriptif sur la commune',
    deleteWarn: 'Ceci supprimera la commune',
    suppressionEffectue: 'Commune supprimée avec succès',
    NewCommune: 'Nouvelle Commune',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de la commune éffectuée avec succès !',
    created_at: 'Créee le',
    edit: 'Modifier la commune',
    AddCommune: 'Nouvelle commune',
    ZoneRequired: 'Vous devez sélectionner une zone',
    selectCommuneTitle: 'Sélectionnez la commune',
    selectOrCreateMessage: "Veuillez sélectionner la commune s'il vous plaît. Si elle n'existe pas, cliquez sur le bouton (+) pour l'ajouter",
  },
  village: {
    village: 'Village',
    villages: 'Les villages',
    nom: 'Nom du village',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom du village',
    DescriptionRequired: 'Veuillez saisir un descriptif sur le village',
    deleteWarn: 'Ceci supprimera le village',
    suppressionEffectue: 'Village supprimé avec succès',
    NewVillage: 'Nouveau Village',
    iscreatedSuccessfully: 'crée avec succès',
    isupdatedSuccessfully: 'Mise à jour du village éffectué avec succès !',
    created_at: 'Crée le',
    edit: 'Modifier le village',
    AddVillage: 'Nouveau village',
    CommuneRequired: 'Vous devez sélectionner une commune',
  },
  localite: {
    localites: 'Les localités',
    AddLocalite: 'Nouvelle localité',
    nolocalite: 'Pour commencer, cliquez sur le bouton (+) et ajoutez une localité ',
  },
  produit: {
    produits: 'Les produits',
    nom: 'Nom du produit',
    description: 'Description du produit',
    NomRequired: 'Veuillez saisir le nom du produit',
    deleteWarn: 'Ceci supprimera le produit',
    suppressionEffectue: 'Produit supprimé avec succès',
    NewProduit: 'Nouveau produit',
    edit: 'Modifier le produit',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de la campagne éffectuée avec succès !',
    AddProduit: 'Nouveau produit',
  },
  uniteProduction: {
    uniteProduction: 'Unité de production',
    uniteProductions: 'Les unités de production',
    nom: 'Nom de l\'unité de production',
    description: 'Description de l\'unité de production',
    NomRequired: 'Veuillez saisir le nom de l\'unité de production',
    deleteWarn: 'Ceci supprimera l\'unité de production',
    suppressionEffectue: 'Unité de production supprimée avec succès',
    NewUniteProduction: 'Nouvelle unité de production',
    edit: 'Modifier l\' unité de production',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de l\'unité de production éffectuée avec succès !',
    AddUniteProduction: 'Nouvelle unité de production',
  },
  categorieQuestion: {
    categorieQuestion: 'catégorie de question',
    categorieQuestions: 'Les catégories de question',
    libelle: 'libellé de la catégorie de question',
    description: 'Description de la catégorie de question',
    NomRequired: 'Veuillez saisir le libellé de la catégorie de question',
    deleteWarn: 'Ceci supprimera la catégorie de question',
    suppressionEffectue: 'Catégorie de question supprimée avec succès',
    NewCategorieQuestion: 'Nouvelle catégorie de question',
    edit: 'Modifier la catégorie de question : ',
    iscreatedSuccessfully: 'créée avec succès',
    isupdatedSuccessfully: 'Mise à jour de la catégorie de question éffectuée avec succès !',
    AddCategorieQuestion: 'Nouvelle catégorie de question',
  },
  question: {
    questions: 'Les questions',
    libelle: 'libellé de la question',
    LibelleRequired: 'Veuillez saisir le libellé de la question',
    deleteWarn: 'Ceci supprimera la question',
    suppressionEffectue: 'Question supprimée avec succès',
    NewQuestion: 'Nouvelle Question',
    edit: 'Modifier la Question : ',
    iscreatedSuccessfully: 'créée avec succès',
    isupdatedSuccessfully: 'Mise à jour de la question éffectuée avec succès !',
    AddQuestion: 'Nouvelle Question',
    CategorieQuestionRequired: 'Vous devez sélectionner une catégorie de question',
  },
  groupement: {
    groupement: 'Groupement',
    groupements: 'Les groupements',
    nom: 'Nom',
    manager: 'Responsable',
    informations: 'Informations du groupement',
    description: 'Description',
    LibelleRequired: 'Veuillez saisir le nom du groupement',
    deleteWarn: 'Ceci supprimera le groupement',
    suppressionEffectue: 'groupement supprimé avec succès',
    NewGroupement: 'Nouveau groupement',
    edit: 'Modifier le groupement : ',
    iscreatedSuccessfully: 'créé avec succès',
    isupdatedSuccessfully: 'Mise à jour du groupement éffectuée avec succès !',
    AddGroupement: 'Nouveau groupement',
    UniteProductionRequired: 'Vous devez sélectionner une unité de production',
  },
  gradeControleur: {
    gradeControleur: 'Grade controleur',
    gradeControleurs: 'Les grades controleurs',
    nom: 'Nom',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom du grade controleur',
    deleteWarn: 'Ceci supprimera le grade controleur',
    suppressionEffectue: 'Grade controleur supprimé avec succès',
    NewGradeControleur: 'Nouveau grade controleur',
    edit: 'Modifier le grade controleur',
    iscreatedSuccessfully: 'créé avec succès',
    isupdatedSuccessfully: 'Mise à jour du grade controleur éffectuée avec succès !',
    AddGradeControleur: 'Nouveau grade controleur',
  },
  typeMedia: {
    typeMedia: 'Type média',
    typeMedias: 'Les types médias',
    nom: 'Nom',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom du type média',
    deleteWarn: 'Ceci supprimera le type média',
    suppressionEffectue: 'Type média supprimé avec succès',
    NewTypeMedia: 'Nouveau type média',
    edit: 'Modifier le type média',
    iscreatedSuccessfully: 'créé avec succès',
    isupdatedSuccessfully: 'Mise à jour du type média éffectuée avec succès !',
    AddTypeMedia: 'Nouveau type média',
  },
  media: {
    suppressionEffectue: 'Média supprimé avec succès',
    deleteWarn: 'Ceci supprimera le média',
    deleteSignature: 'Ceci supprimera la signature',
  },
  controleur: {
    controleur: 'Contrôleur',
    controleurs: 'Les contrôleurs',
    nom: 'Nom',
    grade: 'Grade',
    prenom: 'Prénom',
    email: 'Email',
    numero: 'Numéro',
    adresse: 'Adresse',
    NomRequired: 'Veuillez saisir le nom du contrôleur',
    PrenomRequired: 'Veuillez saisir le prénom du contrôleur',
    NumeroRequired: 'Veuillez saisir le numéro du contrôleur',
    AdresseRequired: 'Veuillez saisir l\'adresse du contrôleur',
    deleteWarn: 'Ceci supprimera le contrôleur',
    suppressionEffectue: 'Contrôleur supprimé avec succès',
    NewControleur: 'Nouveau contrôleur',
    edit: 'Modifier le contrôleur : ',
    iscreatedSuccessfully: 'créé avec succès',
    isupdatedSuccessfully: 'Mise à jour du contrôleur éffectuée avec succès !',
    AddControleur: 'Nouveau contrôleur',
    GradeControleurRequired: 'Vous devez sélectionner un grade ',
    ControleurRequired: 'Vous devez sélectionner un contrôleur ',
    username: 'Nom d\'utilisateur',
    password: 'mot de passe',
    retirerGroupementMessage: "Retirer l'affectation de ce groupement à ce controleur",
    controleur_structure: 'Controleur structure',
  },
  structure: {
    structures: 'Les structures',
    structure: 'Structure',
    nom: 'Nom',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom de la structure',
    deleteWarn: 'Ceci supprimera la structure',
    suppressionEffectue: 'structure supprimée avec succès',
    NewStructure: 'Nouvelle structure',
    edit: 'Modifier le structure',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de la structure éffectuée avec succès !',
    AddStructure: 'Nouvelle structure',
  },
  formation: {
    formations: 'Les formations',
    theme: 'Thème',
    themeEntete: 'Thème',
    objectifs: 'Objectifs',
    lieu: 'Lieu',
    date_for: 'Date début',
    duree_for: 'Durée (en heures)',
    resume: 'Libellé',
    LibelleRequired: 'Veuillez saisir le libellé de la formation',
    deleteWarn: 'Ceci supprimera Formation',
    suppressionEffectue: 'Formation supprimée avec succès',
    NewFormation: 'Nouvelle Formation',
    edit: 'Modifier Formation : ',
    iscreatedSuccessfully: 'créée avec succès',
    isupdatedSuccessfully: 'Mise à jour de la Formation éffectuée avec succès !',
    AddFormation: 'Nouvelle Formation',
    CategorieFormationRequired: 'Vous devez sélectionner une Formation',
  },
  bl: {
    bl: 'catégorie de question',
    bls: 'Les bls',
    libelle: 'Libellé',
    description: 'Description',
    LibelleRequired: 'Veuillez saisir le libellé de la catégorie de question',
    deleteWarn: 'Ceci supprimera la catégorie de question',
    suppressionEffectue: 'Catégorie de question supprimée avec succès',
    NewBl: 'Nouvelle Bl',
    edit: 'Modifier le Bl : ',
    iscreatedSuccessfully: 'créé avec succès',
    isupdatedSuccessfully: 'Mise à jour du Bl éffectué avec succès !',
    AddBl: 'Nouvelle Bl',
  },
  producteur: {
    producteur: 'Producteur',
    producteurs: 'Les producteurs',
    nom: 'Nom',
    grade: 'Grade',
    prenom: 'Prénom',
    email: 'Email',
    groupement: 'Goupements',
    parcelles: 'Parcelles',
    numero: 'Téléphone',
    adresse: 'Adresse',
    NomRequired: 'Veuillez saisir le nom du producteur',
    PrenomRequired: 'Veuillez saisir le prénom du producteur',
    NumeroRequired: 'Veuillez saisir le numéro du producteur',
    AdresseRequired: 'Veuillez saisir l\'adresse du producteur',
    deleteWarn: 'Ceci supprimera le producteur',
    suppressionEffectue: 'Contrôleur supprimé avec succès',
    NewProducteur: 'Nouveau producteur',
    edit: 'Modifier le producteur : ',
    iscreatedSuccessfully: 'créé avec succès',
    isupdatedSuccessfully: 'Mise à jour du producteur éffectuée avec succès !',
    AddProducteur: 'Nouveau producteur',
    LastGard: '',
    created: 'Producers Crées avec succes',
  },
  conteneur: {
    conteneur: 'Conteneur',
    conteneurs: 'Les conteneurs',
    num_cont: 'Numéro',
    num_pb: 'Numéro de Plomb',
    pds_net: 'Poids net (t)',
    NumCountRequired: 'Veuillez saisir le numéro du conteneur',
    deleteWarn: 'Ceci supprimera le conteneur',
    suppressionEffectue: 'Conteneur supprimé avec succès',
    NewConteneur: 'Nouveau Conteneur',
    edit: 'Modifier le Conteneur : ',
    iscreatedSuccessfully: 'créé avec succès',
    isupdatedSuccessfully: 'Mise à jour du Conteneur éffectuée avec succès !',
    AddConteneur: 'Nouveau Conteneur',
    nbr_sac: 'Nombre de sacs',
  },
  operationCulturale: {
    operationCulturale: 'opération culturale',
    operationCulturales: 'Les opérations culturales',
    nom: 'Nom',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom de l\'opération culturale',
    deleteWarn: 'Ceci supprimera l\'opération culturale',
    suppressionEffectue: 'Opération culturale supprimée avec succès',
    NewOperationCulturale: 'Nouvelle opération culturale',
    edit: 'Modifier l\'opération culturale',
    iscreatedSuccessfully: 'créé avec succès',
    isupdatedSuccessfully: 'Mise à jour de l\'opération culturale éffectuée avec succès !',
    AddOperationCulturale: 'Nouvelle opération culturale',
  },
  parcelle: {
    annee: 'Annee',
    culture: 'Culture',
    angrais: 'Angrais',
    semence_ogm: 'Semence OGM',
    village_id: 'Village',
    parcelles: 'Les parcelles',
    nom: 'Nom de la parcelle',
    acc_saison: 'Accessibilité Saison',
    dist_hab: 'Distance des habitations (km)',
    riviere: 'Nombre Rivières',
    date_dernier_usage: 'Année du dernier usage',
    observation: 'Observation',
    validation: 'Validation',
    statut: 'Statut',
    ObservationRequired: 'Observation requis',
    SuperficieRequired: 'Superficie requis',
    visa_ci: 'Visa CI',
    visa_prod: 'Visa Prod',
    produits: 'Produits',
    plan: 'Plan Partielle',
    superficie: 'Superficie (ha)',
    zone_tp: 'Zone Tampon',
    dist_zn_tp: 'Distance Zone Tampon',
    cult_zn_tp: 'Culture Zone Tampon',
    nbr_arb: 'Nombre d\'arbres',
    description: 'Description',
    NomRequired: 'Veuillez saisir le nom de la parcelle',
    deleteWarn: 'Ceci supprimera la parcelle',
    suppressionEffectue: 'Parcelle supprimé avec succès',
    NewParcelle: 'Nouvelle parcelle',
    edit: 'Modifier la parcelle',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de la parcelle éffectuée avec succès !',
    AddParcelle: 'Nouvelle parcelle',
    libelle: 'Libellé',
  },
  recolte: {
    recoltes: 'Les collectes',
    code: 'Code',
    date: 'Date collectes',
    poidsTotal: 'Poids total (en tonnes)',
    commentaire: 'Commentaire',
    recolte: 'Collecte',
    produitCollecte: 'Produit Collecté',
    periodeCollecte: 'Periode de Collecte',
  },
  sacs: {
    nombreSacs: 'Nombres de sacs',
    sac: 'Sac',
  },
  lot: {
    lots: 'Les lots',
    ste_trans: 'Société de transport',
    im_camion: 'Immatriculation du camion',
    date_chrgmnt: 'Date de chargement',
    ste_transRequired: 'Le nom de la société de transport est requis',
    im_camionRequired: "L'immatriculation du camion est requise",
    iscreatedSuccessfully: 'crée avec succès',
    NewLot: 'Nouveau lot',
    isupdatedSuccessfully: 'Lot mise à jours avec succès',
    deleteWarn: 'Supprimer le lot',
    suppressionEffectue: 'Lot supprimé avec succès',
    edit: 'Modifier un lot',
    AddLot: 'Ajouter un lot',
  },
  historique: {
    deleteWarn: ' Vous procédez à la suppression de l\'historique. ',
    deleteContinue: 'Continer ?',
    suppressionEffectue: ' Suppression éffectuée avec succes',
  },
  audit: {
    confirmValidation: "Voulez-vous valider l'audit ?",
    validationCancel: 'Validation annulée',
    dedailAudit: "Détails de l'audit",
  },
  notification: {
    notifications: 'Les notifications',
    notificationDetails: 'Détail notification',
    deleteWarn: ' Vous procédez à la suppression de la notification. ',
    suppressionEffectue: 'Permission supprimée avec succès',
  },
  produitCollecte: {
    produitCollecte: 'Produit Collecté',
    produit: 'Produit',
    campagne_agricole: 'Campagne agricole',
    edit: 'Éditer le produit collecté',
    add: 'Ajouter Produit Collecté',
    iscreatedSuccessfully: 'Produit Collecté ajoutée avec succès',
    isupdatedSuccessfully: 'Produit Collecté mise à jour avec succès',
    deleteWarn: 'Vous procédez à la suppression du Produit Collecté.',
  },
  periodeCollecte: {
    periodeCollecte: 'Périodes de la Collecte',
    date_debut: 'Date debut',
    date_fin: 'Date fin',
    campagne_agricole: 'Campagne agricole',
    edit: 'Éditer le produit collecté',
    add: 'Ajouter Produit Collecté',
    iscreatedSuccessfully: 'Produit Collecté ajoutée avec succès',
    isupdatedSuccessfully: 'Produit Collecté mise à jour avec succès',
    deleteWarn: 'Vous procédez à la suppression du Produit Collecté.',
  },
};
