<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <search id="header-search" class="right-menu-item" />

        <screenfull id="screenfull" class="right-menu-item hover-effect" />

        <!--el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip-->

        <lang-select class="right-menu-item hover-effect" />
        <el-select v-model="camp_id" class="right-menu-item" placeholder="Campagne Agricole" @change="setCampId(camp_id)">
          <el-option
            v-for="item in campagnes_agricoles"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <!-- <el-dropdown class="avatar-container right-menu-item hover-effect" click="fkfjj()">
          <span class="el-dropdown-link">
            <i class="el-icon-message-solid" />
            <el-badge class="mark" :value="notifications.length" />
          </span>
          <el-dropdown-menu slot="dropdown" class="dropdown-scrollable">
            <el-dropdown-item><h3>Liste des Notifications</h3></el-dropdown-item>
            <el-dropdown-item
              v-for="(item, index) in notifications"
              :key="index"
              @click="openNotif(item.id)"
            >
              <hr>
              <span @click="openNotif(item.element_id)">
                <el-alert
                  :title="item.titre"
                  type="info"
                  :description="item.contenu"
                  :closable="false"
                  show-icon
                />
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </template>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img v-if="avatar" :src="avatar+'/128'" class="user-avatar">
          <el-avatar v-else icon="el-icon-user-solid" />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/">
            <el-dropdown-item>
              {{ $t('navbar.dashboard') }}
            </el-dropdown-item>
          </router-link>
          <router-link v-show="userId !== null" :to="`/profile/index`">
            <el-dropdown-item>
              {{ $t('navbar.profile') }}
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item divided>
            <span style="display:block;" @click="logout">{{ $t('navbar.logOut') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import Resource from '@/api/resource';
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import Screenfull from '@/components/Screenfull';
// import SizeSelect from '@/components/SizeSelect';
import LangSelect from '@/components/LangSelect';
import Search from '@/components/HeaderSearch';

const campagnesResource = new Resource('campagne_agricoles');
const notificationsResource = new Resource('notifications');

export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    // SizeSelect,
    LangSelect,
    Search,
  },
  data() {
    return {
      campagnes_agricoles: [],
      notifications: [],
      camp_id: null,
    };
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'name',
      'avatar',
      'device',
      'userId',
    ]),
  },
  created() {
    this.getCampagnesList();
    this.campEnCours();
    this.getNotificationsList();
  },
  methods: {
    async getCampagnesList(){
      const { data } = await campagnesResource.list();
      console.log('CAMPAGNE RECUPERER', data);
      this.campagnes_agricoles = [];
      data.forEach((camp) => {
        this.campagnes_agricoles.push({ 'value': camp.id, 'label': camp.periode });
      });
      console.log('DATA CAMP', this.campagnes_agricoles);
    },
    async getNotificationsList(){
      const { data } = await notificationsResource.list();
      console.log('notifications', data);
      this.notifications = data;
    },
    async openNotif(id){
      console.log('openNoti', id);
      window.location.href = '/audit/' + id;
      // var data = await auditItemsRessource.get(this.$route.params.id);
    },
    changeCampId(id){
      sessionStorage.setItem('camp_id', id);
      this.camp_id = parseInt(sessionStorage.getItem('camp_id'));
      this.$emit('change_camp');
      console.log('Here we are at the route', this.$route.path);
      // this.$router.push((this.$route.path).toString());
      this.$router.go((this.$route.path).toString());
    },
    async campEnCours(){
      const { data } = await campagnesResource.sublist('/en_cours');
      this.camp_id = data.id;
      sessionStorage.setItem('camp_id', this.camp_id);
      await this.$store.dispatch('camp_agricole/set_camp', this.camp_id);
      console.log('STORED CAMP ID', this.$store.getters.camp_id);
      // const camp = sessionStorage.getItem('camp_id');
    },
    setCampId(id){
      this.$store.dispatch('camp_agricole/set_camp', id);
      console.log('STORED CAMP ID CHANGED', this.$store.getters.camp_id);
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      await this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-scrollable {
  max-height: 300px; /* ajustez la hauteur maximale selon vos besoins */
  overflow-y: auto;
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 4px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
