import '@/bootstrap';
import { Message, MessageBox } from 'element-ui';
// import { isLogged, setLogged } from '@/utils/auth';
import store from '@/store';

// Create axios instance
const service = window.axios.create({
  baseURL: '/api', // http://sciagri.test/api http://localhost:8000 /api
  // timeout: 10000000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  (config) => {
    // const token = isLogged();
    // if (token) {
    //   config.headers['Authorization'] = 'Bearer ' + isLogged(); // Set JWT token
    // }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  (response) => {
    // if (response.headers.authorization) {
    //   setLogged(response.headers.authorization);
    //   response.data.token = response.headers.authorization;
    // }
    const res = response.data;
    console.log('RESPONSE  CODE', response, response.data);
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      // to re-login
      MessageBox.confirm(
        'You have been logged out, you can cancel to stay on this page, or log in again',
        'Confirm logout',
        {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload();
        });
      });
    }
    console.log('___-data-___', response.data);
    return response.data;
  },
  (error) => {
    // const res = error.response.data;
    console.log('ERror  CODE', error, error.response.data);
    if (error.response.data.message === 'Unauthenticated') {
      // to re-login
      MessageBox.confirm(
        'You have been logged out, you can cancel to stay on this page, or log in again',
        'Confirm logout',
        {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload();
        });
      });
    }
    if (error.response.status === 401 || error.response.data.code === 401) {
      store.dispatch('user/resetToken').then(() => {
        location.reload();
      });
    }
    let message = error.message;
    if (error.response.data && error.response.data.errors) {
      message = error.response.data.errors;
    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error;
    }

    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
