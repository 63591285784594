import request from '@/utils/request';

/**
 * Simple RESTful resource class
 */
class Resource {
  constructor(uri) {
    this.uri = uri;
  }
  list(query) {
    return request({
      url: '/' + this.uri,
      method: 'get',
      params: query,
    });
  }
  sublist(suburl, query) {
    return request({
      url: '/' + this.uri + suburl,
      method: 'get',
      params: query,
    });
  }
  get(id) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'get',
    });
  }
  getPdf(id, camp_id) {
    return request({
      url: '/' + this.uri + '/' + id + '?' + camp_id,
      method: 'get',
    });
  }
  getAuditPdf(id, audit_id, camp_id) {
    return request({
      url: '/' + this.uri + '/' + id + '?audit_id=' + audit_id + '&campagne_id=' + camp_id,
      method: 'get',
    });
  }
  getControleurPdf(id, camp_id) {
    return request({
      url: '/' + this.uri + '/' + id + '/pdf' + '?' + camp_id,
      method: 'get',
    });
  }
  getProducteurPdf(id, camp_id) {
    return request({
      url: '/' + this.uri + '/' + id + '/parcelles' + '?' + camp_id,
      method: 'get',
    });
  }
  getSacInfo(query) {
    return request({
      url: '/' + this.uri,
      method: 'get',
      params: query,
    });
  }
  store(resource) {
    return request({
      url: '/' + this.uri,
      method: 'post',
      data: resource,
      // validateStatus: function(status) {
      //   return status < 500; // Resolve only if the status code is less than 500
      // },
    });
  }
  update(id, resource) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'put',
      data: resource,
    });
  }
  destroy(id) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'delete',
    });
  }
  extra(id, resource) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'post',
      data: resource,
    });
  }
}

export { Resource as default };
