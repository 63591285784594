export default {
  route: {
    dashboard: 'Homepage',
    introduction: 'Introduction',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Permission Page',
    rolePermission: 'Role and Permission',
    visaRespFiliere: 'Visa Resp. Sector',
    visaChargeShipping: 'Visa charged shipping',
    visaChargeNP: 'Visa Standards & Procedures Officer',
    icons: 'Icons',
    components: 'Components',
    componentIndex: 'Introduction',
    errorPages: 'Error pages',
    page401: '401',
    page404: '404',
    errorLog: 'Log',
    administration: 'Administration',
    users: 'Users',
    userProfile: 'User Profile',
  },
  navbar: {
    logOut: 'Logout',
    dashboard: 'Homepage',
    github: 'Github',
    theme: 'Theme',
    size: 'Overall size',
    profile: 'Profile',
  },
  login: {
    title: 'Log in',
    logIn: 'Connected',
    username: 'Username',
    password: 'Password',
    email: 'Email address',
  },
  documentation: {
    documentation: 'Documentation',
    laravel: 'Laravel',
  },
  permission: {
    addRole: 'New Role',
    editPermission: 'Edit Permission',
    editPermissions: 'Edit Permissions',
    roles: 'Roles',
    switchRoles: 'Activate roles',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel',
    permissionsUpdated: 'The permissions have been updated!',
    extraPermissions: 'Additional permissions',
    addedPermissions: 'Permissions added',
    noSelectionAdd: 'No permission added',
    manage: 'Manage permissions',
    addPermissions: 'Adding permissions',
    tableName: 'Name of the table',
    NameRequired: 'Please enter the name',
    iscreatedSuccessfully: 'Permission successfully added',
    name: 'Permission',
    suppressionEffectue: 'Permission successfully removed',
  },
  guide: {
    description: 'Description',
    button: 'View the Guide',
  },
  components: {
    documentation: 'Documentation',
  },
  general: {
    next: 'Next',
    yes: 'Yes',
    no: 'No',
    send: 'Send',
    description: 'Description',
    nom: 'Name',
    title: 'Title',
    type: 'Type',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    keyword: 'Keywords',
    role: 'Role',
    permissions: 'Permissions',
    menus: 'Menus',
    rolesEtpermissions: 'Roles and permissions',
    deleteWarningText: 'Are you sure you want to delete?',
    deleteConfirmButtonText: 'Yes delete',
    deleteCancelButtonText: 'No leave',
    save: 'Save',
    saving: 'Saving ...',
    closingWarningText: 'Are you sure you want to close?',
    notFound: 'No items found',
    actives: 'Actives',
    visible: 'Visible',
    disponibles: 'Available',
    M_actives: 'Activated',
    F_actives: 'Activated',
    M_desactives: 'Disabled',
    F_desactives: 'Disabled',
    M_supprimes: 'Deleted',
    F_supprimes: 'Deleted',
    corbeille: 'Trashcan',
    restaurer: 'Restore',
    suppressionDefinitive: 'Definitive deletion',
    nonActives: 'Not active',
    nonVisibles: 'Not visible',
    F_actif: 'Active',
    M_actif: 'Active',
    deleteContinue: 'Continue?',
    suppressionAnnule: 'Deletion cancelled',
    filter: 'Filter',
    insert: 'Upload Data',
    init: 'Reset',
    statusUpdated: 'Status update successfully completed!',
    statusUpdateError: 'Sorry, an error occurred while updating the status',
    ouiSupprimer: 'Yes delete',
    irrversibleMessage: 'This action is irreversible',
    SureQuestion: 'Are you sure?',
    periode: 'Time period',
    startDateplaceholder: 'Start date',
    endDateplaceholder: 'End date',
    DaterangeSeparator: 'to',
    ImapeUploadFieldText: 'Click to submit',
    clotures: 'Closed',
    labelColonneCloturer: 'closed',
    allpermissions: 'All permissions',
    formInvalid: 'Your form is not valid! Please check the data !',
    print: 'Print',
    load: 'Load',
    otherInfos: 'Further information',
    reference: 'Reference',
    libelle: 'Wording',
    visaRespFiliere: 'Visa Responsable Filière',
    error: 'Error',
    selectDateHeur: 'Select date and time',
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export selected items',
    placeholder: 'Please enter the file name (default: liste-excel)',
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme choice on the navigation bar, these are two different skinning methods, each with different application scenarios.',
  },
  settings: {
    title: 'Page style configuration',
    theme: 'Theme Color',
    tagsView: 'Show tags',
    fixedHeader: 'Fix the header',
    sidebarLogo: 'Logo',
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close others',
    closeAll: 'Close all',
  },
  user: {
    role: 'Role',
    password: 'Password',
    c_password: 'Confirm',
    nom: 'Last name',
    prenom: 'First name',
    email: 'Email address',
    username: 'Username',
    code: 'Code',
    EditPermissions: 'Modify permissions',
    AddUser: 'Add a user',
    selectRole: 'Select a role',
    PasswordsInconform: 'Passwords are not compliant',
    RoleRequired: 'Please select a role',
    NomRequired: 'Please enter the name',
    EmailRequired: 'Please enter the email address',
    EmailInvalide: 'Please enter a valid email address',
    PasswordRequired: 'Please enter the password',
    deleteWarn: 'This will remove the user',
    deleteContinue: 'Do you want to continue?',
    cancel: 'Cancel',
    suppressionEffectue: 'Deletion successfully completed!',
    suppressionAnnule: 'Deletion cancelled',
    NewUser: 'New user',
    iscreatedSuccessfully: 'has been successfully created',
    PermissionsUpdated: 'Permissions successfully updated!',
    users: 'Users',
    updated: 'User Profile updated successfully',
    user: 'User',
    edit: 'Edit the user',
    PasswordMin6: 'Must have at least 6 characters',
    isupdatedSuccessfully: 'User update successfully completed!',
    changeCredentils: 'Change the password',
    changeAvatar: 'Change   Avatar',
    nochangeCredentils: 'Do not change the password',
  },
  roles: {
    deleteWarn: 'Warning, this will remove the role',
    permissions: 'Permissions',
    nom: 'Name of the role',
    users: 'Users',
    extraMenus: 'Additional menus',
    InheritedfromRole: 'Inherited from the role',
    addRole: 'Add a role',
    name: 'Role',
    NewRole: 'New role',
    iscreatedSuccessfully: 'successfully created!',
    isupdatedSuccessfully: 'The role has been successfully updated',
    NameRequired: 'Make sure you understand the role',
    edit: 'Change the role',
    suppressionEffectue: 'Role successfully deleted',
    description: {
      admin: 'The Super Administrator administers the application',
      manager: 'The Manager is the manager of the application',
      editeur: 'User who can integrate data',
      utilisateur: 'Regular user',
      visiteur: 'visitors only have the right to view',
    },
  },
  region: {
    region: 'Region',
    regions: 'The regions',
    nom: 'Name of the region',
    description: 'Description',
    NomRequired: 'Please enter the name of the region',
    DescriptionRequired: 'Please enter a description of the region',
    deleteWarn: 'This will delete the region',
    suppressionEffectue: 'Region successfully deleted',
    NewRegion: 'New region',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Update of the region successfully completed!',
    created_at: 'Created on',
    edit: 'Change the region',
    AddRegion: 'New region',
    selectRegionTitle: 'Select the region',
    selectOrCreateMessage: 'Please select the region. If it does not exist, click on the (+) button to add it',
  },
  campagneagricole: {
    campagneagricoles: 'Agricultural campaigns',
    nom: 'Name of the campaign',
    description: 'Description',
    NomRequired: 'Please enter the name of the crop year',
    DescriptionRequired: 'Please enter a description of the campaign',
    deleteWarn: 'This will remove the campaign',
    suppressionEffectue: 'Campaign successfully deleted',
    NewCampagneagricole: 'New crop year',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Campaign update successfully completed!',
    created_at: 'Created on',
    edit: 'Modify the campaign',
    AddCampagneagricole: 'New crop year',
    FileCaracteristicsText: 'jpg/png files with a size less than 500kb',
    CampaignBannerFieldText: 'Banner Image',
    prevision: 'Forecast',
    periode: 'Time period',
    previsionFieldRequiredWarningEmpty: 'forecasting is required',
    previsionFieldRequiredWarningType: 'the forecast must be a number',
    yearBeginPlaceholder: 'Start year',
    yearEndPlaceholder: 'End year',

  },
  zone: {
    zone: 'Zone',
    zones: 'The zones',
    nom: 'Name of the zone',
    description: 'Description',
    NomRequired: 'Please enter the name of the area',
    DescriptionRequired: 'Please enter a description on the zone',
    deleteWarn: 'This will delete the zone',
    suppressionEffectue: 'Zone successfully deleted',
    NewZone: 'New Zone',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Update of the zone done successfully!',
    created_at: 'Created on',
    edit: 'Modify the zone',
    AddZone: 'New Zone',
    RegionRequired: 'You must select a region',
    selectZoneTitle: 'Select the zone',
    selectOrCreateMessage: 'Please select the zone. If it does not exist, click on the (+) button to add it',
  },
  commune: {
    commune: 'Municipality',
    communes: 'The municipalities',
    nom: 'Name of the municipality',
    description: 'Description',
    NomRequired: 'Please enter the name of the municipality',
    DescriptionRequired: 'Please enter a description of the municipality',
    deleteWarn: 'This will remove the municipality',
    suppressionEffectue: 'Municipality successfully deleted',
    NewCommune: 'New Municipality',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Update of the municipality successfully completed!',
    created_at: 'Created on',
    edit: 'Modify the municipality',
    AddCommune: 'New Municipality',
    ZoneRequired: 'You must select a zone',
    selectCommuneTitle: 'Select the municipality',
    selectOrCreateMessage: 'Please select the municipality. If it does not exist, click on the (+) button to add it',
  },
  village: {
    village: 'Village',
    villages: 'The villages',
    nom: 'Name of the village',
    description: 'Description',
    NomRequired: 'Please enter the name of the village',
    DescriptionRequired: 'Please enter a description of the village',
    deleteWarn: 'This will delete the village',
    suppressionEffectue: 'Village successfully deleted',
    NewVillage: 'New Village',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Village update successfully completed!',
    created_at: 'Created on',
    edit: 'Edit the village',
    AddVillage: 'New Village',
    CommuneRequired: 'You must select a municipality',
  },
  localite: {
    localites: 'The localities',
    AddLocalite: 'New locality',
    nolocalite: 'To start, click on the (+) button and add a location ',
  },
  produit: {
    produits: 'The products',
    nom: 'Product name',
    description: 'Description',
    NomRequired: 'Please enter the product name',
    deleteWarn: 'This will delete the product',
    suppressionEffectue: 'Product successfully deleted',
    NewProduit: 'New product',
    edit: 'Edit the product',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Campaign update successfully completed!',
    AddProduit: 'New product',
  },
  uniteProduction: {
    uniteProduction: 'Manufacturing unit',
    uniteProductions: 'The Manufacturing units',
    nom: 'Name of the Manufacturing unit',
    description: 'Description of the Manufacturing unit',
    NomRequired: 'Please enter the name of the Manufacturing unit',
    deleteWarn: 'This will delete the Manufacturing unit',
    suppressionEffectue: 'Manufacturing unit successfully deleted',
    NewUniteProduction: 'New Manufacturing unit',
    edit: 'Edit l\' Manufacturing unit',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Update of the Manufacturing unit done with success!',
    AddUniteProduction: 'New Manufacturing unit',
  },
  categorieQuestion: {
    categorieQuestion: 'question category',
    categorieQuestions: 'Question categories',
    libelle: 'wording',
    description: 'Description',
    NomRequired: 'Please enter the wording of the question category',
    deleteWarn: 'This will delete the question category',
    suppressionEffectue: 'Question category successfully deleted',
    NewCategorieQuestion: 'New question category',
    edit: 'Edit the question category: ',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Question category update successfully completed!',
    AddCategorieQuestion: 'New question category',
  },
  question: {
    questions: 'The questions',
    libelle: 'wording ',
    LibelleRequired: 'Please enter the wording of the question',
    deleteWarn: 'This will delete the question',
    suppressionEffectue: 'Question successfully deleted',
    NewQuestion: 'New Question',
    edit: 'Edit Question: ',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Update of the question successfully completed!',
    AddQuestion: 'New Question',
    CategorieQuestionRequired: 'You must select a question category',
  },
  groupement: {
    groupement: 'Grouping',
    groupements: 'Groupings',
    nom: 'Name',
    manager: 'Manager',
    informations: 'Group information',
    description: 'Description',
    LibelleRequired: 'Please enter the name of the grouping',
    deleteWarn: 'This will delete the grouping',
    suppressionEffectue: 'successfully deleted grouping',
    NewGroupement: 'New grouping',
    edit: 'Edit the grouping: ',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Grouping update successfully completed!',
    AddGroupement: 'New grouping',
    UniteProductionRequired: 'You must select a production unit',
  },
  gradeControleur: {
    gradeControleur: 'Controller\'s grade',
    gradeControleurs: 'Controller grades',
    nom: 'Name',
    description: 'Description',
    NomRequired: 'Please enter the name of the grade controller',
    deleteWarn: 'This will delete the controller grade',
    suppressionEffectue: 'Controller grade successfully deleted',
    NewGradeControleur: 'New controller grade',
    edit: 'Edit the controller grade',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Controller grade update successfully completed!',
    AddGradeControleur: 'New controller grade',
  },
  typeMedia: {
    typeMedia: 'Media type',
    typeMedias: 'Media types',
    nom: 'Name',
    description: 'Description',
    NomRequired: 'Please enter the name of the media type',
    deleteWarn: 'This will delete the media type',
    suppressionEffectue: 'Media type successfully deleted',
    NewTypeMedia: 'New media type',
    edit: 'Edit the media type',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Media type update successfully completed!',
    AddTypeMedia: 'New media type',
  },
  media: {
    suppressionEffectue: 'Media successfully deleted',
    deleteWarn: 'This will delete the media',
    deleteSignature: 'This will delete the signature',
  },
  controleur: {
    controleur: 'Controller',
    controleurs: 'The controllers',
    nom: 'Last name',
    grade: 'Grade',
    prenom: 'First name',
    email: 'Email',
    numero: 'Number',
    adresse: 'Address',
    NomRequired: 'Please enter the Last name of the controller',
    PrenomRequired: 'Please enter the controller\'s first name',
    NumeroRequired: 'Please enter the controller number',
    AdresseRequired: 'Please enter the controller\'s address',
    deleteWarn: 'This will delete the controller',
    suppressionEffectue: 'Controller successfully deleted',
    NewControleur: 'New controller',
    edit: 'Edit the controller: ',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Controller update successfully completed!',
    AddControleur: 'New controller',
    GradeControleurRequired: 'You must select a grade ',
    ControleurRequired: 'You must select a controller ',
    username: 'Username',
    password: 'password',
    retirerGroupementMessage: 'Unassign this group from this controller',
    controleur_structure: 'Controler structure',
  },
  structure: {
    structures: 'The structures',
    structure: 'Structure',
    nom: 'Name',
    description: 'Description',
    NomRequired: 'Please enter the name of the structure',
    deleteWarn: 'This will delete the structure',
    suppressionEffectue: 'structure successfully deleted',
    NewStructure: 'New structure',
    edit: 'Edit the structure',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Update of the structure successfully completed!',
    AddStructure: 'New structure',
  },
  formation: {
    formations: 'The trainings',
    theme: 'Topic',
    themeEntete: 'Topic',
    objectifs: 'Objectives',
    lieu: 'Venue',
    date_for: 'Start date',
    duree_for: 'Duration (in hours)',
    resume: 'Wording',
    LibelleRequired: 'Please enter the name of the course',
    deleteWarn: 'This will delete Training',
    suppressionEffectue: 'Successfully deleted training',
    NewFormation: 'New Training',
    edit: 'Edit Training: ',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Training update successfully completed!',
    AddFormation: 'New Training',
    CategorieFormationRequired: 'You must select a Training',
  },
  insertion: {
    insert: 'Load Data',
  },
  bl: {
    bl: 'Bl',
    bls: 'The bls',
    libelle: 'Wording',
    description: 'Description',
    LibelleRequired: 'Please enter the wording of the bl',
    deleteWarn: 'This will delete the bl',
    suppressionEffectue: 'Bl successfully deleted',
    NewBl: 'New Bl',
    edit: 'Edit Bl: ',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Update of the Bl successfully completed!',
    AddBl: 'New Bl',
  },
  producteur: {
    producteur: 'Farmer',
    producteurs: 'The farmers',
    nom: 'Last Name',
    // grade: 'Grade',
    groupement: 'Grouping',
    parcelles: 'Plots',
    prenom: 'First Name',
    email: 'Email',
    numero: 'Phone number',
    adresse: 'Address',
    NomRequired: 'Please enter the name of the farmer',
    PrenomRequired: 'Please enter the first name of the farmer',
    NumeroRequired: 'Please enter the farmer number',
    AdresseRequired: 'Please enter the farmer\'s address',
    deleteWarn: 'This will delete the farmer',
    suppressionEffectue: 'farmer successfully deleted',
    NewProducteur: 'New farmer',
    edit: 'Edit the farmer: ',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Farmer update successfully completed!',
    AddProducteur: 'New farmer',
    LastGard: '',
    created: 'Producers created successfully',
  },
  conteneur: {
    conteneur: 'Container',
    conteneurs: 'The containers',
    num_cont: 'Number',
    num_pb: 'Lead Number',
    pds_net: 'Net weight (t)',
    NumCountRequired: 'Please enter the container number',
    deleteWarn: 'This will delete the container',
    suppressionEffectue: 'Container successfully deleted',
    NewConteneur: 'New Container',
    edit: 'Edit Container: ',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Container update successfully completed!',
    AddConteneur: 'New Container',
    nbr_sac: 'Bags number ',
  },
  operationCulturale: {
    operationCulturale: 'farming operation',
    operationCulturales: 'Farming operations',
    nom: 'Name',
    description: 'Description',
    NomRequired: 'Please enter the name of the farming operation',
    deleteWarn: 'This will delete the farming operation',
    suppressionEffectue: 'farming operation successfully deleted',
    NewOperationCulturale: 'New farming operation',
    edit: 'Edit the farming operation',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'farming operation update successfully completed',
    AddOperationCulturale: 'New farming operation',
  },
  parcelle: {
    annee: 'Annee',
    culture: 'Culture',
    angrais: 'Fertilizer',
    semence_ogm: 'GMO seed',
    produits: 'Products',
    village_id: 'Village',
    parcelles: 'The parcels',
    nom: 'Name of the parcel',
    acc_saison: 'Saison Accessibility ',
    dist_hab: 'Distance from dwellings (km)',
    riviere: 'Rivers',
    date_dernier_usage: 'Year of last use',
    observation: 'Observation',
    ObservationRequired: 'Observation required',
    SuperficieRequired: 'Superficie required',
    validation: 'Validation',
    statut: 'Status',
    visa_ci: 'Visa CI',
    visa_prod: 'Visa Prod',
    plan: 'Parcel Plan',
    superficie: 'Superficie (ha)',
    zone_tp: 'Zone Tampon',
    dist_zn_tp: 'Distance Zone Tampon',
    cult_zn_tp: 'Culture Zone Tampon',
    nbr_arb: 'Trees',
    description: 'Description',
    NomRequired: 'Please enter the name of the parcel',
    deleteWarn: 'This will delete the parcel',
    suppressionEffectue: 'Parcel successfully deleted',
    NewParcelle: 'New parcel',
    edit: 'Edit the parcel',
    iscreatedSuccessfully: 'successfully created',
    isupdatedSuccessfully: 'Update of the parcel successfully completed!',
    AddParcelle: 'New parcel',
    libelle: 'Wording',
  },
  recolte: {
    recoltes: 'Harvests',
    code: 'Code',
    date: 'Harvest Date',
    poidsTotal: 'Total weight (in tons)',
    commentaire: 'Comment',
  },
  sacs: {
    nombreSacs: 'Number of bags',
    sac: 'Bag',
  },
  lot: {
    lots: 'the batches',
    ste_trans: 'Transport company',
    im_camion: 'Truck registration',
    date_chrgmnt: 'Truck loading date',
    ste_transRequired: 'The name of the transport company is required',
    im_camionRequired: 'Truck registration is required',
    iscreatedSuccessfully: 'successfully created',
    NewLot: 'New Batch',
    isupdatedSuccessfully: 'Batch updated successfully',
    deleteWarn: 'Delete Batch',
    suppressionEffectue: 'Batch Deleted successfullys',
    edit: 'Update batch',
    AddLot: 'Add batch',
  },
  historique: {
    deleteWarn: 'You will proceed to the Historique deletion. ',
    deleteContinue: 'continue ?',
    suppressionEffectue: ' Deleted successfully',
  },
  audit: {
    confirmValidation: 'Do you want to validate the audit?',
    validationCancel: 'Validation canceled!',
    dedailAudit: 'Audit details',
  },
};
