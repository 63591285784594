/* eslint-disable no-console */

import { register } from 'register-service-worker';

// if (process.env.NODE_ENV === 'production') {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
    );
  },
  registered() {
    const cacheWhitelist = ['v1'];
    caches.keys().then(function(keyList) {
      return Promise.all(keyList.map(function(key) {
        if (cacheWhitelist.indexOf(key) === -1) {
          console.log('Cache deleting successfully.');
          return caches.delete(key);
        }
      }));
    });
    console.log('Service worker has been registered.');
  },
  cached() {
    console.log('Content has been cached for offline use.');
  },
  updatefound() {
    caches.keys().then(function(keys) {
      for (const key of keys) {
        caches.delete(key).then(r => console.log('New content is downloading.'));
      }
    });
  },
  updated() {
    console.log('New content is available; please refresh.');
    const cacheWhitelist = ['v1'];
    caches.keys().then(function(keyList) {
      return Promise.all(keyList.map(function(key) {
        if (cacheWhitelist.indexOf(key) === -1) {
          console.log('Cache deleting successfully.');
          return caches.delete(key);
        }
      }));
    });
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});
// }
